/* eslint-disable no-nested-ternary */
import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
// import InventoryIcon from '@mui/icons-material/Inventory';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { priceFormatter } from '../../../../../utils/formatters';
import { capitalizeEachWord, getTotalCosts } from '../../../../../utils/utils';
import { PublicOrder } from '../../../../../Models';
import { OrderSection } from '../OrderSection';

const subHeaderStyle = {
    backgroundColor: 'grey.100',
};

export function OfferCard({ order }: { order: PublicOrder }) {
    const userOffer = order.user_offer;
    const chargedTotal = getTotalCosts(userOffer);
    return (
        <OrderSection id="order-summary-data">
            <Stack
                direction="column"
                justifyContent="space-between"
                spacing={2}
                divider={<Divider flexItem />}>
                <Stack direction="row" justifyContent="start" alignItems="center" spacing={1}>
                    <ReceiptIcon color="action" />
                    <Typography variant="h6" fontWeight="medium">
                        Resumen
                    </Typography>
                </Stack>

                <TableContainer id="order-summary-table" sx={{ p: 0 }}>
                    <Table size="small">
                        <TableBody>
                            <>
                                <TableRow sx={subHeaderStyle}>
                                    <TableCell
                                        align="left"
                                        colSpan={2}
                                        sx={{ fontWeight: 'medium' }}>
                                        Productos
                                    </TableCell>
                                    <TableCell align="right" colSpan={1}>
                                        {priceFormatter.format(
                                            userOffer?.prescription_charged_price!,
                                        )}
                                    </TableCell>
                                </TableRow>
                                {userOffer?.products?.map((prod) => (
                                    <TableRow key={prod.name}>
                                        <TableCell
                                            align="left"
                                            colSpan={
                                                3
                                            }>{`- (x${prod.quantity}) ${capitalizeEachWord(prod.name)}`}</TableCell>
                                    </TableRow>
                                ))}
                            </>
                            <TableRow sx={subHeaderStyle}>
                                <TableCell align="left" colSpan={2} sx={{ fontWeight: 'medium' }}>
                                    Total cobrado
                                </TableCell>
                                <TableCell align="right">
                                    {priceFormatter.format(chargedTotal)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </OrderSection>
    );
}
