import {
    collection,
    collectionGroup,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    query,
    where,
    or,
    and,
    QuerySnapshot,
    QueryFilterConstraint,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import {
    DisclosedOrder,
    HealthProviderId,
    ID,
    OrderState,
    PublicOrder,
    UserOffer,
} from '../../Models';
import { disclosedOrderConverter, publicOrderConverter, userOfferConverter } from './converters';
import { app } from '../firebase';
import { fillPatientData } from './crud';

type RecordCallback<T> = (orders: Record<string, T>) => void;

const db = getFirestore(app);
export type SubscriptionReturn = {
    ordersWithUpdates: PublicOrder[];
    emittedOrders: PublicOrder[];
    assignedOrders: DisclosedOrder[];
    approvedOrders: DisclosedOrder[];
};
export const ordersSubscription = (
    healthProviders: HealthProviderId[],
    deliveryZones: string[] | undefined,
    onLoad: (resp: SubscriptionReturn) => void,
) => {
    const queryConstraints: QueryFilterConstraint[] = [where('state', '==', 'emitted')];
    if (deliveryZones && deliveryZones.length) {
        queryConstraints.push(where('delivery.zone', 'in', deliveryZones));
    }
    return onSnapshot(
        query(
            collection(db, 'orders'),
            or(
                and(...queryConstraints),
                and(
                    where('state', 'in', [
                        'assigned',
                        'validated',
                        'approved',
                        'ready_for_pickup',
                        'delivering',
                        'fulfilled', // todo este estdo no debería estar en las suscriptions
                        'cancellation_requested',
                    ] as OrderState[]),
                    where('assigned_pharmacy_id', '==', getAuth(app).currentUser?.uid),
                ),
            ),
            orderBy('creation_timestamp', 'desc'),
            limit(150),
        ),
        (querySnapshot) => onNextOrdersSnapshot(querySnapshot, healthProviders, onLoad),
    );
};

const onNextOrdersSnapshot = (
    querySnapshot: QuerySnapshot,
    healthProviders: HealthProviderId[],
    onLoad: (resp: SubscriptionReturn) => void,
) => {
    const emittedOrders: PublicOrder[] = [];
    const assignedOrders: DisclosedOrder[] = [];
    const approvedOrders: DisclosedOrder[] = [];
    const ordersWithUpdates: PublicOrder[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const docChange of querySnapshot.docChanges()) {
        let order: PublicOrder | DisclosedOrder;
        if (docChange.doc.data().state === 'emitted') {
            order = publicOrderConverter.fromFirestore(docChange.doc);
        } else {
            order = disclosedOrderConverter.fromFirestore(docChange.doc);
        }
        if (
            order.state === 'emitted' &&
            healthProviders.length &&
            !healthProviders.includes(order.health_provider)
        ) {
            break;
        }
        if (
            docChange.type === 'added' ||
            (docChange.type === 'modified' && order.state !== 'emitted')
        ) {
            ordersWithUpdates.push(order);
        }
    }
    querySnapshot.forEach((doc) => {
        const order = publicOrderConverter.fromFirestore(doc);
        switch (order.state) {
            case 'emitted':
                // sólo filtramos los emitted, porque puede ocurrir que se cambien los filtros,
                // pero el pedido ya está asignado. En ese caso querríamos que la farmacia
                // siga manejando ese pedido
                if (healthProviders.length && !healthProviders.includes(order.health_provider)) {
                    return;
                }
                emittedOrders.push(publicOrderConverter.fromFirestore(doc));
                break;
            case 'assigned':
            case 'validated':
            case 'cancellation_requested':
                assignedOrders.push(disclosedOrderConverter.fromFirestore(doc));
                break;
            case 'approved':
            case 'ready_for_pickup':
            case 'delivering':
            case 'fulfilled':
                approvedOrders.push(disclosedOrderConverter.fromFirestore(doc));
                break;
            default:
                break;
        }
    });
    // TODO:
    // todo esto pide la información de los pacientes CADA vez que hay un cambio
    // deberíamos cachear esta info? o firebase ya la cachea?
    fillPatientData([...assignedOrders, ...approvedOrders]).then(() =>
        onLoad({
            ordersWithUpdates,
            emittedOrders,
            assignedOrders,
            approvedOrders,
        }),
    );
};
export const userOffersSubscription = (onLoad: RecordCallback<UserOffer>) =>
    onSnapshot(
        query(
            collectionGroup(db, 'offers'),
            where('state', 'in', ['emitted', 'winner']),
            where('pharmacy_id', '==', getAuth(app).currentUser?.uid),
            orderBy('creation_timestamp', 'desc'),
            limit(500),
        ).withConverter(userOfferConverter),
        (querySnapshot) => {
            const offers: Record<ID, UserOffer> = {};
            querySnapshot.forEach((docSnap) => {
                const data = docSnap.data();
                if (docSnap.exists()) {
                    // puede ocurrir que haya sido eliminada
                    offers[data.order_id] = data;
                }
            });
            onLoad(offers);
        },
    );
