import { useEffect, useState } from 'react';
import { CustomNotification, onNewNotification } from '../services/firebase/messaging';

export const useFCMessaging = (): CustomNotification | undefined => {
    const [lastNotification, setLastNotification] = useState<CustomNotification>();

    useEffect(() => {
        onNewNotification(setLastNotification);
    }, []);

    return lastNotification;
};
