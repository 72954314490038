import Axios from 'axios';
import { DelfarApiEntity } from './delfarApiEntity.enum';
import { defaultRequestInterceptor } from './defaultRequestInterceptor';

// constants
const DEFAULT_TIMEOUT = 10000;
export const delfarApiUrl = process.env.REACT_APP_DELFAR_API_URL;

// APIs axios instances (interceptors below)
export const sessionsApi = Axios.create({
    baseURL: `${delfarApiUrl}/${DelfarApiEntity.SESSIONS}`,
    timeout: DEFAULT_TIMEOUT,
});
export const filesApi = Axios.create({
    baseURL: `${delfarApiUrl}/${DelfarApiEntity.FILES}`,
    timeout: 30000,
});
export const ordersApi = Axios.create({
    baseURL: `${delfarApiUrl}/${DelfarApiEntity.ORDERS}`,
    timeout: DEFAULT_TIMEOUT,
});
export const searchApi = Axios.create({
    baseURL: `${delfarApiUrl}/${DelfarApiEntity.SEARCH}`,
    timeout: DEFAULT_TIMEOUT,
});
export const deliveriesApi = Axios.create({
    baseURL: `${delfarApiUrl}/${DelfarApiEntity.DELIVERIES}`,
    timeout: DEFAULT_TIMEOUT,
});
export const healthProvidersApi = Axios.create({
    baseURL: `${delfarApiUrl}/${DelfarApiEntity.HEALTH_PROVIDERS}`,
    timeout: DEFAULT_TIMEOUT,
});
export const qrsApi = Axios.create({
    baseURL: `${delfarApiUrl}/${DelfarApiEntity.QRS}`,
    timeout: DEFAULT_TIMEOUT,
});
export const referralsApi = Axios.create({
    baseURL: `${delfarApiUrl}/${DelfarApiEntity.REFERRALS}`,
    timeout: DEFAULT_TIMEOUT,
});

export const pharmaciesApi = Axios.create({
    baseURL: `${delfarApiUrl}/${DelfarApiEntity.PHARMACIES}`,
    timeout: DEFAULT_TIMEOUT,
});

// Set default request interceptor for all apis (auth & appcheck headers)
[
    sessionsApi,
    filesApi,
    ordersApi,
    searchApi,
    deliveriesApi,
    healthProvidersApi,
    qrsApi,
    referralsApi,
    pharmaciesApi,
].forEach((api) => api.interceptors.request.use(defaultRequestInterceptor));
