import pdfMake, { TCreatedPdf } from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';

export function createPdf(docDefinition: TDocumentDefinitions) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    return pdfMake.createPdf(docDefinition);
}

export async function getPdfBlob(pdfDoc: TCreatedPdf): Promise<Blob> {
    return new Promise((resolve) => {
        pdfDoc.getBlob((blob) => {
            resolve(blob);
        });
    });
}
