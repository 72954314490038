import React from 'react';
import Dialog from '@mui/material/Dialog';
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import RefreshOutlineIcon from '@mui/icons-material/Refresh';

export default function NewVersionDialog() {
    return (
        <Dialog open>
            <DialogTitle>🎊 Nueva versión</DialogTitle>
            <DialogContent>
                <DialogContentText>Hay una nueva versión disponible.</DialogContentText>
                <DialogContentText>
                    Refrescá la página para actualizar la plataforma y seguir recibiendo pedidos.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        // @ts-ignore: Firefox still has a `forceReload` parameter.
                        window.location.reload(true);
                    }}
                    variant="contained"
                    color="primary"
                    autoFocus
                    endIcon={<RefreshOutlineIcon />}>
                    Refrescar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
