import {
    DataGrid,
    gridClasses,
    GridColDef,
    GridColTypeDef,
    GridRenderCellParams,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarQuickFilter,
    GridValueGetterParams,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { DisclosedOrder, OrderState } from '../../Models';
import StatePill from '../../Components/StatePill';
import { historyPriceFormatter } from '../../utils/formatters';
import { OrdersContext } from '../../services/Orders';
import { getTotalCosts, healthProviderTitle } from '../../utils/utils';
import OrderDialog from '../Orders/Components/OrderDialog';

// const MIN_VALUES = 10;
const DISBURSEMENT_DAYS = 21;
const calculateDisbursementDate = (date: Date) => dayjs(date).add(DISBURSEMENT_DAYS, 'days');
function CustomPreview({ order }: { order: DisclosedOrder }) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                size="small"
                variant="text"
                sx={{
                    m: 0,
                    p: 0,
                    fontWeight: 'inherit',
                    fontSize: 'inherit',
                    textAlign: 'inherit',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                }}>
                {order.short_id}
            </Button>
            {open && <OrderDialog order={order} closeOrder={() => setOpen(false)} open={open} />}
        </>
    );
}

const renderClickToPreview = (params: GridRenderCellParams<string>) => (
    <CustomPreview order={params.row} />
);
const renderState = (params: GridRenderCellParams<OrderState, DisclosedOrder>) => (
    <StatePill state={params.value} />
);
const fullnameGetter = (params: GridValueGetterParams<string, DisclosedOrder>) =>
    params.row.patient?.fullname || '';
const getTotalValue = (params: GridValueGetterParams<number, DisclosedOrder>) =>
    getTotalCosts(params.row.user_offer);
const getDeliveryZone = (params: GridValueGetterParams<string, DisclosedOrder>) =>
    params.row.delivery_zone;
const getHealthProvider = (params: GridValueGetterParams<string, DisclosedOrder>) =>
    healthProviderTitle(params.row);
const getDisbursementDate = (params: GridValueGetterParams<string, DisclosedOrder>) =>
    calculateDisbursementDate(params.row.creation_timestamp).format('DD/MM/YYYY');

const currency: GridColTypeDef = {
    type: 'number',
    valueFormatter: ({ value }) => historyPriceFormatter.format(value),
};

const columns: GridColDef[] = [
    { flex: 2, field: 'id', headerName: 'Id', renderCell: renderClickToPreview },
    {
        flex: 4,
        field: 'fullname',
        headerName: 'Paciente',
        valueGetter: fullnameGetter,
    },
    { flex: 3, field: 'creation_datetime', headerName: 'Fecha' },
    {
        flex: 3,
        field: 'delivery_zone',
        headerName: 'Zona de envío',
        valueGetter: getDeliveryZone,
    },
    {
        flex: 2.5,
        field: 'health_provider',
        headerName: 'Prestadora',
        valueGetter: getHealthProvider,
    },
    {
        flex: 4,
        field: 'total_value',
        headerName: 'Monto de medicamentos',
        ...currency,
        valueGetter: getTotalValue,
    },
    {
        flex: 3,
        field: 'disbursement_date',
        headerName: 'Fecha de desembolso',
        align: 'right',
        valueGetter: getDisbursementDate,
    },
    {
        flex: 3,
        field: 'state',
        headerName: 'Estado',
        align: 'right',
        renderCell: renderState,
    },
];

const initialGridState = {
    columns: {
        columnVisibilityModel: {
            /* status: false,
            traderName: false, */
        },
    },
};

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <Stack
                sx={{ width: '100%', mx: 1, my: 1 }}
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <GridToolbarQuickFilter
                    debounceMs={100}
                    sx={{ px: 2, py: 1, backgroundColor: 'background.default' }}
                />
                {/* <GridToolbarFilterButton/> */}
                {/* <GridToolbarColumnsButton/> */}
                <GridToolbarExport
                    csvOptions={{
                        fileName: `Delfar ${dayjs().format('DD-MM-YYYY HH[hs]')}`,
                        delimiter: ';',
                    }}
                />
            </Stack>
        </GridToolbarContainer>
    );
}

export default function History() {
    const { approved } = useContext(OrdersContext);
    return (
        <Box id="history-main-box" sx={{ height: '100%', p: 0, m: 0 }}>
            <Stack direction="column" height="100%" width="100%" justifyContent="flex-start">
                <Box sx={{ height: '100%', p: 0, m: 0 }}>
                    <DataGrid
                        checkboxSelection
                        rows={approved}
                        columns={columns}
                        rowsPerPageOptions={[50, 100, 500]}
                        disableColumnMenu
                        initialState={initialGridState}
                        density="compact"
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        localeText={{
                            toolbarQuickFilterPlaceholder: 'Buscar...',
                            toolbarExport: 'Exportar',
                            toolbarExportCSV: 'Descargar CSV',
                            toolbarExportPrint: 'Imprimir',
                        }}
                        sx={{
                            height: '100%',
                            width: '100%',
                            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                                outline: 'none',
                            },
                            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                                {
                                    outline: 'none',
                                },
                        }}
                    />
                </Box>
            </Stack>
        </Box>
    );
}
