import { getAllHealthProviders } from './crud';
import { HealthProviderId, HealthProvider, HealthProviderType } from '../../Models';

export const DEFAULT_HEALTH_PROVIDER = ['nhp', 'otc'];

let healthProviders = {} as Record<HealthProviderId, HealthProvider>;

export const loadHealthProviders = (): Promise<void> =>
    getAllHealthProviders().then((resp) => {
        healthProviders = resp;
    });

export const getHealthProviders = (): Record<string, HealthProvider> => healthProviders;

export const translateHealthProvider = (id: HealthProviderId) => {
    if (Object.keys(healthProviders).length && healthProviders[id]) {
        return healthProviders[id].name;
    }
    return id;
};
export const translateHealthProviderType = (type: HealthProviderType | string) => {
    switch (type) {
        case 'prepaga':
            return 'Prepaga';
        case 'obra_social':
            return 'Obra Social';
        case 'nhp':
            return 'Particular';
        default:
            return type;
    }
};
