import React, { memo, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar/AppBar';
import IconButton from '@mui/material/IconButton/IconButton';
import Toolbar from '@mui/material/Toolbar/Toolbar';
import Typography from '@mui/material/Typography/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import BarChartIcon from '@mui/icons-material/BarChart';
import Divider from '@mui/material/Divider/Divider';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Container from '@mui/material/Container/Container';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import CloudQueueTwoToneIcon from '@mui/icons-material/CloudQueueTwoTone';
import Grid from '@mui/material/Unstable_Grid2';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon/SvgIcon';
import { handleLogout } from '../services/auth/handleLogout';
import { AlertSnackbar } from './AlertSnackbar';
import { useAlertSnackbar } from '../hooks/useAlertSnackbar';
import NewVersionDialog from './NewVersionDialog';
import { getCurrentAppVersion } from '../services/webApp/version';

enum Section {
    upper,
    lower,
}

type MenuOption = {
    id: string;
    name: string;
    path: string;
    icon: typeof SvgIcon;
    section: Section;
};
const menuOptions: MenuOption[] = [
    {
        id: 'home',
        name: 'Pedidos',
        path: '/orders',
        icon: ViewKanbanIcon,
        section: Section.upper,
    },
    {
        id: 'history',
        name: 'Historial',
        path: '/historial',
        icon: ViewListIcon,
        section: Section.upper,
    },
    {
        id: 'dashboard',
        name: 'Dashboard',
        path: '/dashboard',
        icon: BarChartIcon,
        section: Section.upper,
    },
];
const currentAppVersion = getCurrentAppVersion();
const TOOLTIP_CONNECTED_TITLE = `Conectado\nv${currentAppVersion}`;
const TOOLTIP_DISCONNECTED_TITLE = `Sin conexión a Delfar\nv${currentAppVersion}`;
function ConnectionIndicator({ isConnected }: { isConnected: boolean }) {
    const { title, color, fontSize } = isConnected
        ? {
              title: TOOLTIP_CONNECTED_TITLE,
              color: 'success.light',
              fontSize: 'small' as 'small' | 'medium',
          }
        : {
              title: TOOLTIP_DISCONNECTED_TITLE,
              color: 'warning.light',
              fontSize: 'medium' as 'small' | 'medium',
          };
    return (
        <Tooltip
            title={
                <Typography align="center" style={{ whiteSpace: 'pre-line' }}>
                    {title}
                </Typography>
            }>
            <CloudQueueTwoToneIcon fontSize={fontSize} sx={{ color }} />
        </Tooltip>
    );
}
function LinkSection({
    options,
    currentOption,
}: {
    options: MenuOption[];
    currentOption: MenuOption | undefined;
}) {
    const isCurrent = (option: MenuOption) => option === currentOption;
    return (
        <List sx={{ mt: 2 }} dense disablePadding>
            {options.map((option) => (
                <Link
                    id={`navbar-drawer-link-${option.id}`}
                    key={option.name}
                    to={option.path}
                    style={{ margin: 0, color: 'inherit', textDecoration: 'inherit' }}>
                    <ListItem sx={{ fontWeight: 'light' }} disablePadding>
                        <ListItemButton sx={{ pl: 0 }}>
                            <ListItemIcon>
                                <option.icon color={isCurrent(option) ? 'primary' : 'action'} />
                            </ListItemIcon>
                            <ListItemText
                                primary={option.name}
                                primaryTypographyProps={{
                                    fontSize: 'h6.fontSize',
                                    color: isCurrent(option) ? 'primary' : 'action',
                                    fontWeight: isCurrent(option) ? 'bold' : '',
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                </Link>
            ))}
        </List>
    );
}

type NavbarProps = {
    isLatestVersion: boolean;
    isConnected: boolean;
};
const Navbar = memo(({ isLatestVersion, isConnected }: NavbarProps) => {
    const { alertState, alertMsg, alertSeverity, newAlert, closeAlert } = useAlertSnackbar();
    const [state, setState] = useState(false);
    const location = useLocation();
    const currentOption = menuOptions.find((o) => location.pathname.includes(o.path));
    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setState(open);
    };
    useEffect(() => {
        if (!isConnected) {
            newAlert('Se perdió la conexión con el servidor', 'warning');
        }
    }, [newAlert, isConnected]);
    return (
        <Box id="navbar" sx={{ minHeight: '100vh' }}>
            <AlertSnackbar
                id="alert-snackbar"
                severity={alertSeverity}
                message={alertMsg}
                open={alertState && !isConnected}
                onClose={() => closeAlert()}
            />
            {!isLatestVersion && <NewVersionDialog />}
            <AppBar position="static">
                <Toolbar variant="dense">
                    <Grid
                        container
                        spacing={2}
                        sx={{ flexGrow: 1 }}
                        direction="row"
                        justifyContent="center"
                        alignItems="center">
                        <Grid direction="row" justifyContent="center">
                            <IconButton
                                id="navbar-drawer-button"
                                onClick={toggleDrawer(true)}
                                edge="start"
                                color="inherit">
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid direction="row">
                            <Typography variant="h6" color="inherit" component="div">
                                {currentOption?.name}
                            </Typography>
                        </Grid>
                        <Grid
                            display="flex"
                            direction="row"
                            xsOffset="auto"
                            justifyContent="center">
                            <ConnectionIndicator isConnected={isConnected} />
                        </Grid>
                    </Grid>
                </Toolbar>
                <Drawer id="navbar-drawer" open={state} onClose={toggleDrawer(false)}>
                    <Box sx={{ height: '100%', m: 3 }} role="presentation">
                        <Stack
                            sx={{ height: '100%' }}
                            direction="column"
                            justifyContent="space-between">
                            <Box>
                                <Box sx={{ mb: 2 }}>
                                    <img
                                        src="/logo-completo-rojo-small.png"
                                        alt="Delfar title"
                                        width={150}
                                    />
                                </Box>
                                <Divider />
                                <LinkSection
                                    currentOption={currentOption}
                                    options={menuOptions.filter((o) => o.section === Section.upper)}
                                />
                            </Box>
                            <Box>
                                <LinkSection
                                    currentOption={currentOption}
                                    options={menuOptions.filter((o) => o.section === Section.lower)}
                                />
                                <Button
                                    id="navbar-drawer-logout-button"
                                    sx={{ width: '100%' }}
                                    variant="outlined"
                                    startIcon={<LogoutIcon />}
                                    size="small"
                                    onClick={() =>
                                        handleLogout().catch((err) => {
                                            console.error('Logout error:', err);
                                        })
                                    }>
                                    Cerrar Sesión
                                </Button>
                            </Box>
                        </Stack>
                    </Box>
                </Drawer>
            </AppBar>
            <Container sx={{ pt: 3, height: '91vh' }} maxWidth="xl">
                <Outlet />
            </Container>
        </Box>
    );
});
export default Navbar;
