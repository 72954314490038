import { useLocalStorage } from 'react-use';
import { createContext } from 'react';
import { LocalStorageKey } from '../services/LocalStorage';

export const LocalConfigContext = createContext({
    requestDeliveryAutomatically: true,
    toggleRequestDeliveryAutomatically: () => {},
});
export const useLocalConfig = () => {
    const [requestDeliveryAutomatically, setRequestDeliveryAutomatically] = useLocalStorage(
        LocalStorageKey.ordersRequestDeliveryAutomatically,
        true,
    );
    const toggleRequestDeliveryAutomatically = () =>
        setRequestDeliveryAutomatically(!requestDeliveryAutomatically);

    return {
        requestDeliveryAutomatically: !!requestDeliveryAutomatically,
        toggleRequestDeliveryAutomatically,
    };
};
