import { useEffect, useState } from 'react';
import { Howl } from 'howler';

export const useAudio = (
    src: string | string[],
    sprite: Record<string, [number, number]>,
    volume: number,
) => {
    const [sound] = useState(new Howl({ src, sprite, volume }));

    useEffect(() => {
        sound.volume(volume);
    }, [sound, volume]);
    return sound;
};
