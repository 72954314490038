import { ordersApi } from './config/delfarApi';
import {
    CreateOfferRequestDto,
    CreateOfferResponseDto,
    GetPrescriptionDataResponseDto,
    ValidateOfferRequestDto,
} from './dto/orders.dto';

export const requestHpAuth = async (orderId: string): Promise<boolean> =>
    ordersApi.post(`${orderId}/request_hp_auth`, undefined).then((r) => r.data);

export const createOffer = async (
    orderId: string,
    bodyData: CreateOfferRequestDto,
): Promise<CreateOfferResponseDto> =>
    ordersApi.post<CreateOfferResponseDto>(`${orderId}/offers`, bodyData).then((r) => r.data);

export const validateOffer = async (
    orderId: string,
    bodyData: ValidateOfferRequestDto,
): Promise<boolean> => ordersApi.patch<boolean>(`${orderId}/offer`, bodyData).then((r) => r.data);

export const getPrescriptionData = async (
    orderId: string,
): Promise<GetPrescriptionDataResponseDto> =>
    ordersApi
        .get<GetPrescriptionDataResponseDto>(`${orderId}/prescription_data`)
        .then((r) => r.data);
