/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useState } from 'react';
import LocalStorage, { LocalStorageKey } from '../services/LocalStorage';
import { useAudio } from './useAudio';

const defaultVolume = '100';

type Sound = 'order' | 'delivery';

const spriteStartStop: Record<Sound, [number, number]> = {
    order: [0, 1759],
    delivery: [2000, 2632],
};
const sprite = Object.fromEntries(
    Object.entries(spriteStartStop).map(([key, [start, stop]]) => [key, [start, stop - start]]),
) as Record<Sound, [number, number]>;

const getLocalVolume = () =>
    parseInt(LocalStorage.getValue(LocalStorageKey.notificationsVolume, defaultVolume), 10);
const setLocalVolume = (volume: number) =>
    LocalStorage.setValue(LocalStorageKey.notificationsVolume, volume.toString());

export const SoundNotificationsContext = createContext({
    playNotification: (sound: Sound, skipDebounce?: boolean) => {},
    currentVolume: getLocalVolume(),
    setVolume: (value: number) => {},
});
const DEBOUNCE_MILISECONDS = 1000;
export const useSoundNotifications = () => {
    const [lastPlayTimestamp, setLastPlayTimestamp] = useState(new Date());
    const [currentVolume, setCurrentVolume] = useState(getLocalVolume());
    const audio = useAudio('/notifications.mp3', sprite, currentVolume / 100);

    const setVolume = (newVolume: number) => {
        // newVolume is a number from 1 to 100
        setCurrentVolume(newVolume);
        setLocalVolume(newVolume);
    };
    const playNotification = (sound: Sound, skipDebounce?: boolean) => {
        // agregamos un debounce para que no se ejecuten muy seguido el sonido
        if (
            skipDebounce ||
            new Date().getTime() - lastPlayTimestamp.getTime() > DEBOUNCE_MILISECONDS
        ) {
            audio.play(sound);
            setLastPlayTimestamp(new Date());
        }
    };
    return {
        playNotification,
        currentVolume,
        setVolume,
    };
};
