import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CancelIcon from '@mui/icons-material/Cancel';
import { canCancelOffer, canEditOffer, PublicOrder } from '../../../../../Models';

export default function OfferOptionsMenu({
    order,
    toggleEditOffer,
    onCancelOffer,
}: {
    order: PublicOrder;
    toggleEditOffer: () => void;
    onCancelOffer: () => void;
}) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);
    const handleCancelOffer = () => {
        onCancelOffer();
        handleClose();
    };
    const handleEditOffer = () => {
        toggleEditOffer();
        handleClose();
    };
    return (
        <Box id="order-options">
            <IconButton id="open-menu-button" onClick={handleClick}>
                <EditIcon />
            </IconButton>
            <Menu
                id="order-options-menu"
                anchorEl={anchorEl}
                open={open}
                elevation={10}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <Box>
                    <MenuList>
                        {canEditOffer(order) && (
                            <MenuItem id="edit-offer-option" onClick={handleEditOffer}>
                                <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Editar</ListItemText>
                            </MenuItem>
                        )}
                        {canCancelOffer(order) && (
                            <MenuItem id="cancel-offer-option" onClick={handleCancelOffer}>
                                <ListItemIcon>
                                    <CancelIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Cancelar oferta</ListItemText>
                            </MenuItem>
                        )}
                    </MenuList>
                </Box>
            </Menu>
        </Box>
    );
}
