export const numberFormatter = new Intl.NumberFormat('en-US');
export const priceFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
export const historyPriceFormatter = new Intl.NumberFormat('es-ES', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});
export const pctFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
});
export const dniFormatter = (dni: string) => {
    if (!dni || dni === '') return '';
    const n = 3;
    return Array.from(dni)
        .reverse()
        .reduce((list: string[], elem, i) => {
            list.push(elem);
            if ((i + 1) % n === 0) list.push('.');
            return list;
        }, [])
        .reverse()
        .join('');
};
