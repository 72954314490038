import { getToken } from 'firebase/app-check';
import { appCheck } from '../firebase';

export const getAppCheckToken = async (): Promise<string | undefined> => {
    try {
        const appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);
        return appCheckTokenResponse.token;
    } catch (err) {
        console.error(err);
        return undefined;
    }
};
