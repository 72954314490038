import {
    FirestoreDataConverter,
    DocumentData,
    QueryDocumentSnapshot,
    SnapshotOptions,
} from 'firebase/firestore';
import { User } from '../../Models';
import { DEFAULT_HEALTH_PROVIDER } from '../HealthProviders';

export function upgradeUser(user: DocumentData): DocumentData {
    const newUser = { ...user };
    if (newUser.v === undefined) newUser.v = '1';

    if (newUser.v === '1') {
        // upgrade from v1 to v2
        newUser.v = '2';
        if (!newUser.health_providers) {
            newUser.health_providers = DEFAULT_HEALTH_PROVIDER;
        }
        newUser.geocode.center = {
            lat: newUser.geocode.lat,
            lng: newUser.geocode.lng,
        };
        delete newUser.geocode.lat;
        delete newUser.geocode.lng;
    }
    if (newUser.v === '2') {
        // upgrade from v2 to v3
        newUser.v = '3';
        newUser.coordinates = {
            lat: newUser.geocode.center.lat,
            lng: newUser.geocode.center.lng,
        };
    }
    return newUser;
}

export const userDataConverter: FirestoreDataConverter<User> = {
    toFirestore(user: User): DocumentData {
        return user;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): User {
        let user = snapshot.data(options)!;
        user = upgradeUser(user);
        return {
            id: snapshot.id,
            v: user.v,
            address: user.address,
            contact_number: user.contact_number,
            email: user.email,
            coordinates: user.coordinates,
            name: user.name,
            health_providers: user.health_providers,
            delivery_zones: user.delivery_zones || [],
            mp_credentials_state: user.mp_credentials_state,
        };
    },
};
