import { ReactElement } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

type ExtraProps = Record<string, unknown>;

interface ConfirmDialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    message: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    onlyConfirm?: boolean;
    extraContent?: ReactElement<ExtraProps>;
}

function ConfirmDialog({
    open,
    onClose,
    onConfirm,
    title,
    message,
    confirmButtonText,
    cancelButtonText,
    onlyConfirm,
    extraContent,
}: ConfirmDialogProps) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText style={{ whiteSpace: 'pre-line' }}>{message}</DialogContentText>
                {/* RENDER EXTRA CONTENT */}
                {!!extraContent && extraContent}
            </DialogContent>
            <DialogActions>
                {/* CANCEL BUTTON (CLOSE) */}
                {!onlyConfirm && (
                    <Button onClick={onClose} color="primary">
                        {cancelButtonText || 'Cancelar'}
                    </Button>
                )}
                {/* CONFIRM BUTTON */}
                <Button onClick={onConfirm} color="primary" autoFocus>
                    {confirmButtonText || 'Confirmar'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;
