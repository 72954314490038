import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { getPerformance } from 'firebase/performance';

const recaptchaV3SiteKey = process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY || '';

export const firebaseConfig: Record<string, string> = {
    apiKey: process.env.REACT_APP_API_KEY!,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN!,
    projectId: process.env.REACT_APP_PROJECT_ID!,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET!,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID!,
    appId: process.env.REACT_APP_APP_ID!,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID!,
};
export const app = initializeApp(firebaseConfig);

if (process.env.REACT_APP_ENV === 'local' && window.location.hostname === 'localhost') {
    connectAuthEmulator(getAuth(app), 'http://127.0.0.1:5001', {
        disableWarnings: true,
    });
    connectFunctionsEmulator(getFunctions(app), 'localhost', 5002);
    connectFirestoreEmulator(getFirestore(app), 'localhost', 5003);
    connectStorageEmulator(getStorage(app), 'localhost', 5004);
} else {
    getAnalytics(app);
    getPerformance(app);
}

export const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(recaptchaV3SiteKey),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true,
});

// No podemos tener más 30 parámetros "or"
// https://firebase.google.com/docs/firestore/query-data/queries#limits_on_or_queries
export const MAX_QUERY_CONJUNCTIONS = 30; // determinado por firestore
