import { getMessaging, getToken, isSupported, MessagePayload, onMessage } from 'firebase/messaging';
import { app, firebaseConfig } from './index';
import { sendRegistrationToken } from '../auth/sessions';

const handleFirebaseCloudMessage = (messagePayload: MessagePayload) => {
    // Función que procesa un nuevo message
    if (!messagePayload.data) {
        return;
    }
    const payloadData = messagePayload.data as PayloadData;
    if (
        !payloadData.app_config_notification ||
        !possibleFCMNotifications.includes(payloadData.app_config_notification as FCMNotification)
    ) {
        return;
    }
    const appConfigNotification = payloadData.app_config_notification as FCMNotification;
    const notification: CustomNotification = {
        fcmMessageId: messagePayload.messageId,
        notificationType: appConfigNotification,
    };
    Promise.all(callbackFunctions.map((func) => func(notification))).catch((e) =>
        console.error('One FCM callback function failed', e),
    );
};

export const setupMessaging = async () => {
    const pushMessagesSupported = await isSupported();
    if (process.env.REACT_APP_ENV === 'local') {
        console.error('Push notification is not supported in Firebase emulator');
        return Promise.resolve(true);
    }
    if (!pushMessagesSupported) {
        console.error('Push notification is not supported');
        return Promise.resolve(true);
    }
    const messaging = getMessaging(app);
    // Registramos, para foreground, la función que procesa los messages
    onMessage(messaging, handleFirebaseCloudMessage);

    let registrationToken;
    try {
        registrationToken = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_PUSH_VAPID_KEY,
        });
    } catch (e) {
        console.error('FCMessaging was not initialized', e);
        return Promise.resolve(false);
    }
    try {
        return await sendRegistrationToken(registrationToken);
    } catch (e) {
        console.error('Unable to register FCM token', e);
        return Promise.resolve(false);
    }
};
const possibleFCMNotifications = ['new_update_available'] as const;
export type FCMNotification = (typeof possibleFCMNotifications)[number];
// Llevamos un registro de las funciones que queremos llamar cuando hay un nuevo mensaje
export type FCMCallbackFunction = (messagePayload: CustomNotification) => void;
const callbackFunctions: FCMCallbackFunction[] = [];
export const onNewNotification = (callback: FCMCallbackFunction) => {
    callbackFunctions.push(callback);
};
export type CustomNotification = {
    fcmMessageId: string;
    notificationType: FCMNotification;
};
type PayloadData = {
    app_config_notification?: string;
};

// Registramos el service worker para recibir notificaciones en el background
if ('serviceWorker' in navigator) {
    const firebaseConfigQueryString = new URLSearchParams(firebaseConfig).toString();
    navigator.serviceWorker
        .register(`./firebase-messaging-sw.js?${firebaseConfigQueryString}`, { scope: '/' })
        .then(() => {
            navigator.serviceWorker.addEventListener('message', ({ data }) => {
                // Registramos, para el background, la función que procesa los messages
                if ('fcmPayload' in data) {
                    handleFirebaseCloudMessage(data.fcmPayload);
                }
            });
        })
        .catch((e) => console.error('Unable to register firebase messaging service worker', e));
} else {
    console.error('Service Worker is not supported');
}
