import { collection, getFirestore, limit, onSnapshot, query, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Delivery, ID } from '../../Models';
import { app } from '../firebase';
import deliveryConverter from './converters';

const db = getFirestore(app);

const deliveriesSubscription = (onLoad: (deliveries: Record<ID, Delivery> | undefined) => void) =>
    onSnapshot(
        query(
            collection(db, 'deliveries'),
            where('pharmacy_id', '==', getAuth(app).currentUser?.uid),
            where('state', 'not-in', ['discarded', 'archived']),
            limit(300), // todo agregar creation_timestamp para reducir la cantidad de lecturas
        ).withConverter(deliveryConverter),
        (querySnapshot) => {
            const deliveries: Record<ID, Delivery> = {};
            querySnapshot.forEach((docSnap) => {
                const delivery = docSnap.data();
                if (docSnap.exists()) {
                    deliveries[delivery.order_id] = delivery;
                }
            });
            onLoad(deliveries);
        },
    );

export default deliveriesSubscription;
