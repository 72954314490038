import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Grid2 from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import { canCancelOffer, DisclosedOrder, PublicOrder } from '../../../../../Models';
import OfferOptionsMenu from './OfferOptionsMenu';
import OrderStateOptions from './OrderStateOptions';
import { healthProviderTitle } from '../../../../../utils/utils';
import { usePrescriptionData } from '../../../../../services/api/queries/usePrescriptionData';

const hpTitleStyle = {
    p: 0.5,
    mb: 1,
    borderRadius: 1.5,
    backgroundColor: 'grey.200',
};

type OrderDialogTitleProps = {
    order: PublicOrder | DisclosedOrder;
    closeOrder: () => void;
    toggleEditOffer: () => void;
    submitCancelOffer: () => void;
};
function OrderDialogTitle({
    order,
    closeOrder,
    toggleEditOffer,
    submitCancelOffer,
}: OrderDialogTitleProps) {
    const { otcProducts } = usePrescriptionData(order);
    return (
        <DialogTitle sx={{ pt: 1, pb: 0 }}>
            <Grid2 container spacing={1} alignItems="center">
                <Grid2 xs alignItems="center">
                    <Typography
                        id="order-creation-datetime-text"
                        variant="body2"
                        color="text.secondary">
                        {order.creation_datetime}
                    </Typography>
                    <Typography id="order-id" variant="body2" color="text.secondary">
                        {`# ${order.id}`}
                    </Typography>
                </Grid2>
                <Grid2 xs>
                    <Typography
                        id="health-provider-title"
                        variant="h6"
                        align="center"
                        sx={hpTitleStyle}>
                        {healthProviderTitle(order, !!otcProducts.length)}
                    </Typography>
                </Grid2>
                <Grid2
                    container
                    xs={4}
                    sx={{
                        justifyContent: 'flex-end',
                    }}>
                    <Grid2>
                        <OrderStateOptions order={order} onStateChange={closeOrder} />
                    </Grid2>
                    {canCancelOffer(order) && (
                        <Grid2>
                            <OfferOptionsMenu
                                order={order}
                                toggleEditOffer={toggleEditOffer}
                                onCancelOffer={submitCancelOffer}
                            />
                        </Grid2>
                    )}
                    <Grid2>
                        <IconButton id="close-button" sx={{}} onClick={closeOrder}>
                            <CloseIcon />
                        </IconButton>
                    </Grid2>
                </Grid2>
            </Grid2>
            <Divider />
        </DialogTitle>
    );
}

export default OrderDialogTitle;
