import { pharmaciesApi } from './config/delfarApi';

const authorizeMp = {
    warmup: (): Promise<void> =>
        pharmaciesApi.post<void>('/setup-mercado-pago', { code: 'warmup' }).then((r) => r.data),
    authorize: (code: string): Promise<void> =>
        pharmaciesApi.post<void>('/setup-mercado-pago', { code }).then((r) => r.data),
};

export const updateHealthProviders = async (hps: string[]): Promise<void> =>
    pharmaciesApi.post<void>('health_providers', { health_providers: hps }).then((r) => r.data);

export default {
    authorizeMp,
    updateHealthProviders,
};
