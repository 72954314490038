import { useQuery } from '@tanstack/react-query';
import { getPrescriptionData } from '../orders.endpoints';
import { getOrderDocumentsPdf } from '../files.endpoints';
import { isOTC, PublicOrder } from '../../../Models';

const config = {
    initialDataUpdatedAt: 0, // para considerar desactualizada initialData y fetchear al toque
    refetchInterval: 60 * 1000,
    refetchIntervalInBackground: false,
};
export const usePrescriptionData = (order: PublicOrder) => {
    const hook = useQuery({
        queryKey: ['getPrescriptionData', order.id],
        queryFn: ({ queryKey }) => getPrescriptionData(queryKey[1]),
        initialData: {
            credential_url: '',
            electronic_prescriptions: [],
            paper_prescriptions: [],
            otc_products: [],
        },
        ...config,
    });
    const hook2 = useQuery({
        queryKey: ['getOrderDocumentsPdf', order.id],
        queryFn: ({ queryKey }) => getOrderDocumentsPdf(queryKey[1]),
        initialData: {
            blobData: undefined,
            filename: undefined,
        },
        enabled: !isOTC(order),
        ...config,
    });
    const { data: prescription } = hook;
    const hasPaperPrescriptions =
        !!prescription?.paper_prescriptions?.length || !!prescription?.otc_products?.length;
    const hasElectronicPrescriptions = !!prescription?.electronic_prescriptions?.length;
    const hasOnlyElectronicPrescriptionData =
        !prescription.otc_products?.length &&
        !hasPaperPrescriptions &&
        prescription?.electronic_prescriptions?.every((presc) => !!presc?.prescription_data);
    const hasSomeElectronicPrescriptionData = prescription?.electronic_prescriptions?.some(
        (presc) => !!presc?.prescription_data,
    );
    const electronicProducts = hasSomeElectronicPrescriptionData
        ? prescription?.electronic_prescriptions
              ?.flatMap((ep) => ep?.prescription_data?.products || [])
              ?.map((p) => ({
                  name: p.name,
                  quantity: p.quantity,
                  presentation: p.presentation,
                  generic_name: p.generic_name,
              })) || []
        : [];
    const prescriptionFilesUrls = [
        ...(prescription.paper_prescriptions || []),
        ...(prescription.electronic_prescriptions?.map((p) => p.url) || []),
        ...(prescription.otc_products
            ?.filter((i) => i.url) // <== solo otc con url de imagen (dejamos fuera los que solo tienen "name")
            .map((p) => p.url) || []),
    ];
    const { data } = hook2;
    let printableFilesUrl = '';
    if (data.blobData) {
        printableFilesUrl = URL.createObjectURL(data.blobData);
    }

    return {
        ...hook,
        hasPaperPrescriptions,
        hasElectronicPrescriptions,
        hasOnlyElectronicPrescriptionData,
        hasSomeElectronicPrescriptionData,
        electronicProducts,
        paperPrescriptions: prescription.paper_prescriptions || [],
        electronicPrescriptions: prescription.electronic_prescriptions || [],
        otcProducts: prescription.otc_products || [],
        prescriptionFilesUrls,
        credentialUrl: prescription.credential_url,
        printableFilesUrl,
    };
};
