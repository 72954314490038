import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import React from 'react';

function ValidatingSubmitButton({ disabled }: { disabled: boolean }) {
    const [text, title] = [
        'Aprobar venta',
        'Al aprobar la venta, el paciente recibirá una notificación con el precio de los productos.',
    ];
    return (
        <Tooltip disableHoverListener={disabled} title={title} enterDelay={1000}>
            <Button
                type="submit"
                variant="contained"
                size="medium"
                color="primary"
                disabled={disabled}
                endIcon={<CheckIcon />}>
                {text}
            </Button>
        </Tooltip>
    );
}

export { ValidatingSubmitButton };
