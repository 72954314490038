import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { User } from '../../Models';
import { app } from '../firebase';
import { userDataConverter } from './converters';

const db = getFirestore(app);

export const userSubscription = (onLoad: (user: User | undefined) => void) =>
    onSnapshot(
        doc(db, 'pharmacies', getAuth(app).currentUser!.uid).withConverter(userDataConverter),
        (doc_) => onLoad(doc_.data()),
    );
