import React from 'react';
import Collapse from '@mui/material/Collapse';
import FormGroup from '@mui/material/FormGroup';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider/Divider';
import Typography from '@mui/material/Typography';

type ColumnConfigProps = {
    isOpen: boolean;
    tooltipTitle: string;
    switchChecked: boolean;
    switchOnChange: () => void;
    label: string;
};
export default function ColumnConfig({
    isOpen,
    tooltipTitle,
    switchChecked,
    switchOnChange,
    label,
}: ColumnConfigProps) {
    return (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Divider />
            <FormGroup sx={{ py: 1, px: 1 }}>
                <Tooltip
                    title={
                        <Typography style={{ whiteSpace: 'pre-line' }}>{tooltipTitle}</Typography>
                    }>
                    <FormControlLabel
                        control={
                            <Switch
                                size="small"
                                checked={switchChecked}
                                onChange={switchOnChange}
                            />
                        }
                        label={label}
                        labelPlacement="end"
                    />
                </Tooltip>
            </FormGroup>
        </Collapse>
    );
}
