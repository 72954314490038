import { AxiosError } from 'axios';
import LocalStorage, { LocalStorageKey } from '../LocalStorage';
import sessionsApi from '../api/sessions.endpoints';

export const getSessionId = () => LocalStorage.getValue(LocalStorageKey.userSessionId, '');

const setSessionId = (sessionId: string = '') => {
    LocalStorage.setValue(LocalStorageKey.userSessionId, sessionId);
};

export const createSession = async (): Promise<void> => {
    // Forzar creacion de session
    const sessionId = await sessionsApi.createSession();
    setSessionId(sessionId);
};

export const poll = async (): Promise<string> => {
    let sessionId = getSessionId();
    try {
        if (!sessionId || sessionId === '') {
            await createSession();
            sessionId = getSessionId();
        }
        await sessionsApi.poll(sessionId);
        return sessionId;
    } catch (error) {
        const err = error as AxiosError;
        const { status } = err.response!;
        if (status === 403 || status === 404) {
            await createSession().catch(() => {
                throw new Error('Unable to force session creation');
            });
        } else {
            throw error;
        }
    }
    return sessionId;
};

export const endSession = async () => {
    const sessionId = getSessionId();
    if (!sessionId || sessionId === '') return Promise.resolve(false);

    await sessionsApi.registerPollingEnd(sessionId).finally(() => {
        setSessionId();
    });
    return true;
};

export const sendRegistrationToken = async (registrationToken: string) => {
    const sessionId = getSessionId();
    return sessionsApi.sendRegistrationToken(sessionId, registrationToken);
};
