/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { FormattedNumber } from 'react-intl';
import 'dayjs/locale/es';
import { FormContainer, useFieldArray, useForm } from 'react-hook-form-mui';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Alert from '@mui/material/Alert';
import ListAltIcon from '@mui/icons-material/ListAlt';
import {
    inEmittedState,
    inValidatingState,
    isOTC,
    isScheduled,
    PublicOrder,
} from '../../../../../Models';
import { FormValues } from '../../../types/editableOfferCard.formValues';
import { OrderSection } from '../OrderSection';
import { createOffer, validateOffer } from '../../../../../services/api/orders.endpoints';
import { CreateOfferRequestDto } from '../../../../../services/api/dto/orders.dto';
import { getPrescriptionsCharge } from '../../../utils/getPrescriptionsCharge';
import { sanitizeProductsForm } from '../../../utils/sanitizeProductsForm';
import { getInitFormValues } from '../../../utils/getInitFormValues';
import { PrescriptionInput } from './ProductInputs';
import { ValidatingSubmitButton } from './ValidatingSubmitButton';
import { EmittedSubmitButton } from './EmittedSubmitButton';
import { InputState } from '../InputState';
import { capitalizeEachWord } from '../../../../../utils/utils';
import { usePrescriptionData } from '../../../../../services/api/queries/usePrescriptionData';
import ConfirmDialog from '../../../../../Components/ConfirmDialog';
import { ScheduleDatePicker } from './ScheduleDatePicker';

const filesInfoMessage = (isOtc: boolean) => {
    let firstMsg = 'Estas son las recetas del paciente.';
    if (isOtc) {
        firstMsg = 'Estos son los productos de venta libre del paciente.';
    }
    return `${firstMsg}\n 
Asegurarte de tener stock de todos los medicamentos.

Podés arrastrar la imagen con el mouse para moverla.

Podés programar stock para pedir en droguería o confirmar stock para entrega inmediata`;
};

function TotalAmount({ totalCharged }: { totalCharged: number }) {
    return (
        <Stack direction="column" justifyContent="flex-start" alignItems="center">
            <Stack direction="row" spacing={2}>
                <Typography variant="h6" fontWeight="medium">
                    Total a cobrar:
                </Typography>
                <Typography id="total-charged" variant="h6" fontWeight="medium" color="primary">
                    <FormattedNumber
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        currency="USD"
                        currencyDisplay="narrowSymbol"
                        value={totalCharged}
                        minimumFractionDigits={2}
                    />
                </Typography>
            </Stack>
        </Stack>
    );
}

type ElectronicPrescriptionItemProps = {
    name: string;
    quantity: number;
    monodrug?: string;
    presentation?: string;
};
function ProductListItem({
    name,
    quantity,
    presentation,
    monodrug,
}: ElectronicPrescriptionItemProps) {
    const primaryText = `${quantity}x ${capitalizeEachWord(name)}`;

    let secondaryText = '';
    if (monodrug) {
        secondaryText = `${monodrug}`;
    }
    if (presentation) {
        if (!monodrug) {
            secondaryText = `${presentation}`;
        } else {
            secondaryText += ` - ${presentation}`;
        }
    }
    return (
        <ListItem sx={{ py: 0.5 }}>
            <Typography fontSize={18}>{primaryText}</Typography>
            {secondaryText && (
                <Typography
                    fontSize={16}
                    sx={{
                        color: 'text.secondary',
                    }}>
                    {`${' - '}${secondaryText}`}
                </Typography>
            )}
        </ListItem>
    );
}
type DialogContent = {
    title: string;
    message: string;
};
const dialogs = {
    freeWarning: {
        title: '🧐 ¿Es gratis?',
        message: `Uno de los productos tiene precio $0.\n¿Estás seguro que el producto es gratis?`,
    },
    lowPriceWarning: {
        title: '🧐 ¿Precio muy bajo?',
        message: `Uno de los productos tiene el precio bajo.\n¿El precio está bien ingresado?`,
    },
};
const BTN_WIDTH = 220;
type EditableOfferCardProps = {
    order: PublicOrder;
    inputState: InputState;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    onDone: () => void;
};
export function EditableOfferCard({
    order,
    inputState,
    setLoading,
    onDone,
}: EditableOfferCardProps) {
    const {
        hasOnlyElectronicPrescriptionData,
        electronicProducts,
        otcProducts,
        prescriptionFilesUrls,
    } = usePrescriptionData(order);
    const [{ dialogOpen, onConfirmDialog, dialogContent }, setDialog] = useState<{
        dialogOpen: boolean;
        onConfirmDialog: () => Promise<void>;
        dialogContent: DialogContent;
    }>({
        dialogOpen: false,
        onConfirmDialog: () => Promise.resolve(),
        dialogContent: dialogs.freeWarning,
    });
    const formContext = useForm<FormValues>({
        defaultValues: getInitFormValues(order.user_offer, electronicProducts, otcProducts),
    });
    const { watch, control } = formContext;
    const productsForm = useFieldArray({
        control,
        name: 'products',
        rules: { minLength: 1 },
    });
    const [scheduledEnabled, setScheduleEnabled] = useState(
        !!order.user_offer?.scheduled_stock_datetime,
    );
    const totalPrescriptionCharge = getPrescriptionsCharge(watch().products);

    const handleSubmit = async () => {
        setLoading(true);

        if (inEmittedState(order)) {
            const createData: CreateOfferRequestDto = {};
            if (scheduledEnabled) {
                createData.scheduled_stock_datetime = watch().scheduled_stock_datetime;
            }
            try {
                await createOffer(order.id, createData);
            } catch (err) {
                console.error('EditableOfferCard => handleSubmit', err);
            } finally {
                onDone();
            }
        } else {
            const sanitizedProducts = sanitizeProductsForm(watch().products);
            const localValidateOffer = () =>
                validateOffer(order.id, {
                    products: sanitizedProducts,
                })
                    .then(onDone)
                    .catch((err) => console.error('EditableOfferCard => handleSubmit', err));

            if (sanitizedProducts.filter((pp) => pp.unit_price === 0).length) {
                setDialog({
                    dialogOpen: true,
                    onConfirmDialog: localValidateOffer,
                    dialogContent: dialogs.freeWarning,
                });
            } else if (sanitizedProducts.filter((pp) => pp.unit_price <= 100000).length) {
                setDialog({
                    dialogOpen: true,
                    onConfirmDialog: localValidateOffer,
                    dialogContent: dialogs.lowPriceWarning,
                });
            } else {
                await localValidateOffer();
            }
        }
    };
    return (
        <OrderSection
            id="editable-offer-data"
            sx={{ height: '100%', maxHeight: 1, overflow: 'auto' }}>
            <Stack
                direction="column"
                minHeight="100%"
                justifyContent="space-between"
                alignItems="space-between">
                <FormContainer formContext={formContext}>
                    <Stack direction="column" spacing={1}>
                        <Stack
                            direction="row"
                            justifyContent="start"
                            alignItems="center"
                            spacing={1}>
                            <ListAltIcon color="action" />
                            <Typography variant="h6" fontWeight="medium">
                                Productos{' '}
                                {inValidatingState(order) &&
                                    productsForm &&
                                    `(${productsForm.fields.length})`}
                            </Typography>
                        </Stack>

                        {inEmittedState(order) && (
                            <List dense>
                                {/* Electronic prescription con texto (name) */}
                                {hasOnlyElectronicPrescriptionData &&
                                    electronicProducts.map((p) => (
                                        <ProductListItem
                                            key={p.name}
                                            name={p.name}
                                            quantity={p.quantity}
                                            monodrug={p.generic_name}
                                            presentation={p.presentation}
                                        />
                                    ))}
                                {/* OTC con texto (name)} */}
                                {!!otcProducts.filter((i) => i.name).length &&
                                    otcProducts
                                        .filter((i) => i.name)
                                        .map((p) => (
                                            <ProductListItem
                                                key={p.name}
                                                name={p.name}
                                                quantity={1}
                                            />
                                        ))}
                            </List>
                        )}
                        {inEmittedState(order) && hasOnlyElectronicPrescriptionData && (
                            <Alert severity="info">
                                Este es un pedido con receta electrónica. En el siguiente paso
                                podrás ver la receta.
                            </Alert>
                        )}
                        {inEmittedState(order) && !!otcProducts.filter((i) => i.name).length && (
                            <Alert severity="info">
                                Estos son los productos de <b>venta libre</b> del paciente.
                                Asegurarte de tener stock de todos los medicamentos.
                            </Alert>
                        )}
                        {inEmittedState(order) &&
                            !hasOnlyElectronicPrescriptionData &&
                            !!prescriptionFilesUrls.length && (
                                <Typography
                                    variant="body1"
                                    fontWeight="normal"
                                    color="grey.400"
                                    align="center"
                                    style={{ whiteSpace: 'pre-line' }}>
                                    {filesInfoMessage(isOTC(order))}
                                </Typography>
                            )}
                        {inValidatingState(order) && (
                            <PrescriptionInput
                                order={order}
                                control={control}
                                productsForm={productsForm}
                                disabled={inputState === 'disabled'}
                            />
                        )}
                    </Stack>
                </FormContainer>
                <FormContainer formContext={formContext} onSuccess={handleSubmit}>
                    <Stack direction="column">
                        <Divider flexItem sx={{ my: 1 }} />
                        {inValidatingState(order) && (
                            <TotalAmount totalCharged={totalPrescriptionCharge} />
                        )}
                        <Stack direction="row" justifyContent="flex-end" spacing={5} sx={{ pb: 1 }}>
                            {inEmittedState(order) && (
                                <EmittedSubmitButton
                                    width={BTN_WIDTH}
                                    setScheduleEnabled={setScheduleEnabled}
                                    inputState={inputState}
                                />
                            )}
                            {inValidatingState(order) && (
                                <ValidatingSubmitButton disabled={order.state !== 'assigned'} />
                            )}
                        </Stack>
                    </Stack>
                </FormContainer>
            </Stack>
            <ConfirmDialog
                title={dialogContent.title}
                message={dialogContent.message}
                open={dialogOpen}
                onClose={() => {
                    setDialog({ dialogOpen: false, onConfirmDialog, dialogContent });
                    setLoading(false);
                }}
                onConfirm={onConfirmDialog}
            />
            {/* CONFIRM SCHEDULE DIALOG */}
            <ConfirmDialog
                title="📆 Programar disponibilidad de stock"
                message="Selecciona la fecha y hora en que todos los productos estarán listos para la entrega:"
                confirmButtonText="Programar"
                open={!isScheduled(order) && scheduledEnabled}
                onClose={() => setScheduleEnabled(false)}
                onConfirm={handleSubmit}
                extraContent={<ScheduleDatePicker control={control} inputState={inputState} />}
            />
        </OrderSection>
    );
}
