import React from 'react';
import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography/Typography';

function Title({ text }: { text: string }) {
    return (
        <Box>
            <Typography variant="h5" fontWeight="regular">
                {text}
            </Typography>
        </Box>
    );
}
export default Title;
