import React, { useContext } from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import StartIcon from '@mui/icons-material/Star';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import {
    Delivery,
    DisclosedOrder,
    isReferredOrder,
    isSchedulePending,
    PublicOrder,
    requiresApproval,
} from '../../../../Models';
import { healthProviderTitle } from '../../../../utils/utils';
import '../../styles/HpAuthStateIcon.css';
import { SubStateIcon } from './SubStateIcon';
import { UserContext } from '../../../../services/User';
import { usePrescriptionData } from '../../../../services/api/queries/usePrescriptionData';

const REFERRED_ORDER_TOOLTIP = `Paciente referido a tu farmacia.

😎 Tenés prioridad sobre este pedido . 
El pedido se asignará inmediatamente a vos cuando confirmes o programes stock.`;
type OrderPreviewProps = {
    order: PublicOrder | DisclosedOrder;
    onClick: () => void;
    delivery?: Delivery;
};
type CardColors = {
    primaryTextColor: string;
    secondaryTextColor: string;
    backgroundColor: string;
};
export default function OrderPreview({ order, onClick, delivery }: OrderPreviewProps) {
    const { user } = useContext(UserContext);
    const { otcProducts } = usePrescriptionData(order);
    let cardColors: CardColors;
    if (order.state === 'cancellation_requested') {
        cardColors = {
            primaryTextColor: 'white',
            secondaryTextColor: 'white',
            backgroundColor: 'warning.light',
        };
    } else if (requiresApproval(order)) {
        if (order.state === 'approved' && isSchedulePending(order)) {
            cardColors = {
                primaryTextColor: 'info.main',
                secondaryTextColor: 'text.primary',
                backgroundColor: 'background.paper',
            };
        } else {
            cardColors = {
                primaryTextColor: 'white',
                secondaryTextColor: 'primary.contrastText',
                backgroundColor: 'info.main',
            };
        }
    } else {
        cardColors = {
            primaryTextColor: 'info.main',
            secondaryTextColor: 'text.primary',
            backgroundColor: 'background.paper',
        };
    }

    return (
        <Card id={`order-preview-${order.id}`} sx={{ backgroundColor: cardColors.backgroundColor }}>
            <CardActionArea onClick={onClick}>
                <CardContent sx={{ px: 2, py: 1, width: 1 }}>
                    <Stack
                        sx={{ mb: 1 }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center">
                        <Typography id="health-provider-title" variant="body2">
                            {healthProviderTitle(order, !!otcProducts.length)}
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            {order.creation_datetime}
                        </Typography>
                    </Stack>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-end">
                        <Grid xs>
                            <Stack direction="row" spacing={1} alignItems="flex-end">
                                <Typography
                                    id="neighbourhood"
                                    noWrap
                                    variant="body2"
                                    sx={{ mt: 2, color: cardColors.secondaryTextColor }}>
                                    {order.delivery_zone}
                                </Typography>
                                {isReferredOrder(user, order) && (
                                    <Tooltip
                                        title={
                                            <Typography style={{ whiteSpace: 'pre-line' }}>
                                                {REFERRED_ORDER_TOOLTIP}
                                            </Typography>
                                        }
                                        enterDelay={1000}>
                                        <StartIcon sx={{ color: cardColors.primaryTextColor }} />
                                    </Tooltip>
                                )}
                            </Stack>
                        </Grid>
                        <Grid xs="auto" sx={{ ml: 7 }}>
                            <SubStateIcon order={order} delivery={delivery} />
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
