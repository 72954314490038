import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PublicOrder, DisclosedOrder } from '../Models';
import { useSoundNotifications } from './useSoundNotifications';
import useDesktopNotifications from './useDesktopNotifications';
import { healthProviderTitle } from '../utils/utils';

const MAX_COUNT = 3;
const INTERVAL_MINUTES = 5;

export const useAssignedNotifications = (order: PublicOrder | DisclosedOrder) => {
    const { id: orderId, state: orderState } = order;
    const [count, setCount] = useState(0);
    const soundNotifications = useSoundNotifications();
    const desktopNotifications = useDesktopNotifications();
    const navigate = useNavigate();
    const title = '⚡️ Hay un pedido esperándote!';
    const body = healthProviderTitle(order);
    const onClick = () => navigate(`/orders/${order.id}`, { replace: true });

    const runNotification = () => {
        // si la order no esta en assigned, no hacemos nada
        if (orderState !== 'assigned') {
            return true;
        }
        // si la order esta en assigned:
        // la primera vez no hacemos nada, porque la primera notificacion se dispara a nivel global
        if (count === 0) {
            setCount(count + 1);
            return true;
        }
        // luego, si count es menor o igual a MAX_COUNT, disparamos notificacion
        if (count <= MAX_COUNT) {
            setCount(count + 1);
            // delay u offset que evita disparos en simultaneo cuando hay varias orders assigned previas a cargar la pagina
            const delay = (Math.floor(Math.random() * 30) + 1) * 1000; // de 1 a 30 seg, intervalo discreto de 1 seg
            setTimeout(() => {
                // disparar notificacion
                desktopNotifications.showNotification(title, body, onClick);
                soundNotifications.playNotification('order');
            }, delay);
        }
        // console.log('runNotification', { orderId, count }); // debug
        return true;
    };
    const hook = useQuery({
        queryKey: ['assignedNotifications', orderId],
        queryFn: () => runNotification(),
        enabled: !!orderId && orderState === 'assigned',
        staleTime: 60000, // no disparar misma notificacion en menos de 1 minuto desde la ultiuma vez
        refetchInterval: 1000 * 60 * INTERVAL_MINUTES, // disparar notificacion cada INTERVAL_MINUTES minutos
        refetchIntervalInBackground: true,
        refetchOnWindowFocus: false,
    });

    return hook;
};
