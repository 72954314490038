export enum LocalStorageKey {
    // user keys
    userSessionId = 'user.sessionId',
    // notifications keys
    notificationsVolume = 'notifications.volume',
    // orders config keys
    ordersRequestDeliveryAutomatically = 'orders.requestDeliveryAutomatically',
}
const setValue = (key: LocalStorageKey, value: string) => localStorage.setItem(key, value);
const getValue = (key: LocalStorageKey, defaultValue: string) =>
    localStorage.getItem(key) || defaultValue;

export default { setValue, getValue };
