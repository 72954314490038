import { deleteDoc, doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import urlJoin from 'url-join';
import axios from 'axios';
import { DisclosedOrder, ID, Observation, OrderState, PublicOrder } from '../../Models';
import { app } from '../firebase';
import { patientConverter } from './converters';
import { delfarApiUrl } from '../api/config/delfarApi';

const db = getFirestore(app);

const ordersBaseUrl = urlJoin(delfarApiUrl as string, 'orders');
const orderUrl = (orderId: ID) => urlJoin(ordersBaseUrl, orderId);

export const getPatientData = (order: DisclosedOrder) =>
    (order.customer_id
        ? getDoc(doc(db, 'customers', order.customer_id).withConverter(patientConverter))
        : getDoc(
              doc(db, 'orders', order.id, 'restricted', 'patient').withConverter(patientConverter),
          )
    ).then((patientDocSnap) => {
        // todo pasar a triggers
        if (patientDocSnap.exists()) {
            // eslint-disable-next-line no-param-reassign
            order.patient = patientDocSnap.data();
        }
    });

export const fillPatientData = (orders: DisclosedOrder[]) =>
    Promise.all(orders.map((order) => getPatientData(order)));

export const changeState = (orderId: ID, state: OrderState) =>
    setDoc(
        doc(db, 'orders', orderId), // todo pasar a triggers
        { state },
        { merge: true },
    );

export const toReadyForPickup = (order: PublicOrder) => changeState(order.id, 'ready_for_pickup');
export const toCanceled = (order: PublicOrder) => changeState(order.id, 'canceled');

export const cancelPriceOffer = (order: PublicOrder) =>
    deleteDoc(doc(db, 'orders', order.id, 'offers', order.user_offer!.id));

export const addObservation = (orderId: ID, observation: Observation) =>
    axios.post(urlJoin(orderUrl(orderId), 'observations'), {
        pharmacy_id: getAuth(app).currentUser!.uid, // todo pasar a triggers,
        type: observation.type,
        text: observation.text,
    });
