import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Button from '@mui/material/Button';
import LockIcon from '@mui/icons-material/Lock';
import HistoryIcon from '@mui/icons-material/History';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import PersonIcon from '@mui/icons-material/Person';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import { capitalizeEachWord, whatsappLink } from '../../../../../utils/utils';
import { dniFormatter } from '../../../../../utils/formatters';
import { CopyTypography } from '../../../../../Components/CopyTypography';
import { DisclosedOrder, isPast, needsHpAuthToken } from '../../../../../Models';
import { OrderSection } from '../OrderSection';
import { requestHpAuth } from '../../../../../services/api/orders.endpoints';
import ConfirmDialog from '../../../../../Components/ConfirmDialog';
import { translateHealthProvider } from '../../../../../services/HealthProviders';

dayjs.extend(relativeTime);

const mergePatientAddress = (address: {
    lat: number;
    lng: number;
    input: string;
    decode: string;
    number: string;
    street: string;
    zipcode: string;
    city: string;
    town: string;
    province: string;
}) =>
    `${address.street} ${address.number}, ${address.zipcode}, ${address.city}, ${address.province}`;
export function PatientCard({ order }: { order: DisclosedOrder }) {
    const hasToken = needsHpAuthToken(order);
    return (
        <OrderSection id="patient-data">
            {hasToken && (
                <Stack
                    direction="row"
                    spacing={5}
                    justifyContent="center"
                    alignItems="center"
                    divider={<Divider orientation="vertical" flexItem />}>
                    <PatientSub order={order} />
                    <TokenHpSub order={order} />
                </Stack>
            )}
            {!hasToken && <PatientSub order={order} />}
        </OrderSection>
    );
}

function PatientSub({ order }: { order: DisclosedOrder }) {
    return (
        <Stack
            direction="column"
            spacing={0}
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{
                minWidth: '18rem',
            }}>
            <Stack direction="row" justifyContent="start" alignItems="center" spacing={1}>
                <PersonIcon color="action" />
                <Typography id="patient-fullname" variant="h5" noWrap textAlign="left">
                    {capitalizeEachWord(order.patient.fullname)}
                </Typography>
            </Stack>

            {!isPast(order) && (
                <>
                    {order.patient.national_id && (
                        <CopyTypography
                            id="patient-national_id"
                            variant="body1"
                            textAlign="left"
                            value={order.patient.national_id}
                            message="Nº de documento copiado"
                            maxWidth="%60">
                            {`DNI: ${dniFormatter(order.patient.national_id)}`}
                        </CopyTypography>
                    )}
                    <Stack direction="row" spacing={4} alignItems="center">
                        <CopyTypography
                            id="patient-phone_number"
                            variant="body1"
                            textAlign="left"
                            value={order.patient.phone_number}
                            message="Teléfono copiado"
                            maxWidth="%60">
                            {`Tel: ${order.patient.phone_number}`}
                        </CopyTypography>
                        <IconButton
                            size="small"
                            id="patient-whatsapp-button"
                            onClick={() =>
                                window.open(whatsappLink(order.patient.phone_number), '_blank')
                            }>
                            <WhatsAppIcon />
                        </IconButton>
                    </Stack>
                    {order.patient.address && (
                        <CopyTypography
                            id="patient-address"
                            variant="body1"
                            textAlign="left"
                            value={mergePatientAddress(order.patient.address)}
                            message="Dirección copiada"
                            maxWidth="%60">
                            {`Dirección: ${mergePatientAddress(order.patient.address)}`}
                        </CopyTypography>
                    )}
                </>
            )}
        </Stack>
    );
}

function TokenHpSub({ order }: { order: DisclosedOrder }) {
    type TokenState = 'none' | 'requested' | 'sent' | 'error';
    type TokenAppState = TokenState | 'requesting' | 'req_error';
    type Color =
        | 'warning'
        | 'success'
        | 'info'
        | 'error'
        | 'secondary'
        | 'inherit'
        | 'primary'
        | undefined;

    const tokenState = order.hp_authentication?.state || 'none';
    const [tokenAppState, setTokenAppState] = useState('none' as TokenAppState);

    useEffect(() => {
        setTokenAppState(tokenState);
    }, [tokenState]);

    // **** Dialog modal
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMsg, setDialogMsg] = useState('');
    const [dialogOnConfirm, setDialogOnConfirm] = useState(() => (): void => {});
    const [dialogOnClose, setDialogOnClose] = useState(() => (): void => {});
    const [dialogOnlyConfirm, setDialogOnlyConfirm] = useState(false);

    const handleCloseDialog = () => setDialogOpen(false);
    const handleClickReqOnReceived = () => {
        setDialogTitle('¿Solicitar nuevo token?');
        setDialogMsg('Ya recibiste un token. ¿Querés solicitarlo de nuevo?');
        setDialogOnConfirm(() => handleConfirmReqOnReceived);
        setDialogOnClose(() => handleCloseDialog);
        setDialogOnlyConfirm(false);
        setDialogOpen(true);
    };
    const handleConfirmReqOnReceived = () => {
        handleRequestToken();
        handleCloseDialog();
    };
    const handleCloseReqTooMany = () => {
        setTokenAppState('sent');
        handleCloseDialog();
    };
    const handleDialogTokenReqTooMany = () => {
        setDialogTitle('No se puede solicitar token');
        setDialogMsg('Por favor, esperá unos segundos antes de solicitarlo de nuevo.');
        setDialogOnConfirm(() => handleCloseReqTooMany);
        setDialogOnClose(() => handleCloseReqTooMany);
        setDialogOnlyConfirm(true);
        setDialogOpen(true);
    };
    // ****

    const handleRequestToken = async () => {
        setTokenAppState('requesting');
        await requestHpAuth(order.id).catch((err) => {
            if (err?.response?.status === 429) {
                handleDialogTokenReqTooMany();
                console.log(err);
                return;
            }
            setTokenAppState('req_error');
            console.log(err);
        });
    };

    const btnColor: Record<TokenAppState, Color> = {
        none: 'primary',
        requesting: 'secondary',
        requested: 'secondary',
        sent: 'primary',
        error: 'secondary',
        req_error: 'secondary',
    };
    const btnProps: Record<string, Record<TokenAppState, any>> = {
        onClick: {
            none: () => handleRequestToken(),
            requesting: () => undefined,
            requested: () => undefined,
            sent: () => handleClickReqOnReceived(),
            error: () => handleRequestToken(),
            req_error: () => handleRequestToken(),
        },
        color: btnColor,
        icon: {
            none: <LockIcon />,
            requesting: <CircularProgress size={18} />,
            requested: <CircularProgress size={18} />,
            sent: <RefreshIcon />,
            error: <CircularProgress size={18} />,
            req_error: <CircularProgress size={18} />,
        },
        text: {
            none: 'Solicitar token',
            requesting: 'Solicitando token...',
            requested: 'Esperando respuesta del paciente...',
            sent: 'Solicitar nuevo token',
            error: 'Esperando respuesta del paciente...',
            req_error: 'Esperando respuesta del paciente...',
        },
    };

    return (
        <>
            <Stack
                direction="column"
                spacing={1}
                width={1}
                alignItems="stretch"
                justifyContent="space-between">
                <Typography id="token-hp-title" variant="body1" textAlign="left">
                    {`Token ${translateHealthProvider(order.health_provider)}`}
                </Typography>
                <Typography id="token-hp-value" variant="h5" textAlign="center">
                    {order.hp_authentication?.token || '----'}
                </Typography>
                {order.hp_authentication?.token && order.hp_authentication?.last_sent_timestamp && (
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            color: 'text.secondary',
                        }}>
                        <HistoryIcon sx={{ mr: 1 }} />
                        <Typography id="token-hp-time" textAlign="center" fontSize={13}>
                            {dayjs(order.hp_authentication.last_sent_timestamp).fromNow()}
                        </Typography>
                    </Stack>
                )}
                <Button
                    id="request-token-button"
                    size="small"
                    variant="contained"
                    onClick={btnProps.onClick[tokenAppState]}
                    endIcon={btnProps.icon[tokenAppState]}
                    color={btnProps.color[tokenAppState]}
                    disabled={tokenAppState !== 'none' && tokenAppState !== 'sent'}>
                    {btnProps.text[tokenAppState]}
                </Button>
            </Stack>

            <ConfirmDialog
                title={dialogTitle}
                message={dialogMsg}
                open={dialogOpen}
                onClose={dialogOnClose}
                onConfirm={dialogOnConfirm}
                confirmButtonText="OK"
                onlyConfirm={dialogOnlyConfirm}
            />
        </>
    );
}
