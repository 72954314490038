import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/es';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Control, Controller } from 'react-hook-form-mui';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { InputState } from '../InputState';
import { FormValues } from '../../../types/editableOfferCard.formValues';

const SCHEDULED_DATE_TOOLTIP =
    'Fecha y hora en la cual tendrás el medicamento en stock.\n\nEl paciente será informado que el medicamento será enviado en esta fecha y hora.';
const HOURS_MIN = 7;
const HOURS_MAX = 20;
const MINUTES_INTERVAL = 15;

function ScheduleDatePicker({
    control,
    inputState,
}: {
    control: Control<FormValues, any>;
    inputState: InputState;
}) {
    return (
        <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{ pb: 1 }}>
            <>
                <Typography variant="body1">Fecha de disponibilidad:</Typography>
                <Tooltip
                    title={
                        <Typography style={{ whiteSpace: 'pre-line' }}>
                            {SCHEDULED_DATE_TOOLTIP}
                        </Typography>
                    }
                    placement="top">
                    <InfoIcon sx={{ ml: 2 }} color="disabled" fontSize="small" />
                </Tooltip>
            </>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                <Controller
                    control={control}
                    name="scheduled_stock_datetime"
                    rules={{ required: true }}
                    render={({ field }) => (
                        <DateTimePicker
                            disablePast
                            disabled={inputState === 'disabled'}
                            timeSteps={{ minutes: MINUTES_INTERVAL }}
                            shouldDisableTime={(timeValue, view) =>
                                (view === 'hours' && timeValue.hour() < HOURS_MIN) ||
                                timeValue.hour() > HOURS_MAX
                            }
                            skipDisabled
                            slotProps={{
                                textField: { size: 'small' },
                                layout: {
                                    sx: {
                                        ul: {
                                            width: '4.5rem',
                                            // scrollbar:
                                            '::-webkit-scrollbar': {
                                                width: '12px',
                                            },
                                            '::-webkit-scrollbar-track': {
                                                backgroundColor: 'transparent',
                                            },
                                            '::-webkit-scrollbar-thumb': {
                                                backgroundColor: 'rgba(0,0,0,.2)',
                                                borderRadius: '10px',
                                                border: '2px solid transparent',
                                                backgroundClip: 'content-box',
                                            },
                                            '::-webkit-scrollbar-thumb:hover': {
                                                backgroundColor: 'rgba(0,0,0,.4)',
                                            },
                                        },
                                        li: {
                                            width: '4rem',
                                            fontSize: '17px',
                                        },
                                    },
                                },
                            }}
                            format={
                                field.value?.isToday() ? '[hoy] hh:mm A' : 'dddd DD MMM hh:mm A'
                            }
                            value={field.value}
                            inputRef={field.ref}
                            onChange={field.onChange}
                        />
                    )}
                />
            </LocalizationProvider>
        </Stack>
    );
}

export { ScheduleDatePicker };
