import { memo } from 'react';
import DocViewer from '@cyntler/react-doc-viewer';
import PDFRenderer from '@cyntler/react-doc-viewer/dist/cjs/renderers/pdf';
import { JPGRenderer, PNGRenderer } from './renderers';
import { HeaderBar } from './HeaderBar';

const FileViewer = memo(
    ({ files }: { files: string[] }) => (
        <DocViewer
            style={{ height: '95%', margin: 0, padding: 0 }}
            documents={files.map((f, i) => ({
                uri: f,
                // fileType: f.contentType,
                fileName: `${i}`,
            }))}
            language="es"
            pluginRenderers={[PDFRenderer, PNGRenderer, JPGRenderer]}
            config={{
                header: {
                    overrideComponent: HeaderBar,
                },
                pdfZoom: {
                    defaultZoom: 2.1, // 1 as default,
                    zoomJump: 0.7, // 0.1 as default,
                },
                pdfVerticalScrollByDefault: false,
            }}
        />
    ),
    (prevProps, nextProps) => prevProps.files.sort().join(',') === nextProps.files.sort().join(','),
);

export default FileViewer;
