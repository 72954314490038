import printJS, { Configuration } from 'print-js';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { PageBreak, TDocumentDefinitions } from 'pdfmake/interfaces';
import { createPdf, getPdfBlob } from '../../../utils/pdfmake';

function pdfQrs(qrUrls: string[], qrColor: string, docTitle: string): TDocumentDefinitions {
    const qrsContent = qrUrls.flatMap((url, index) => [
        {
            style: 'qrTable',
            table: {
                body: [
                    [
                        {
                            qr: url,
                            foreground: qrColor,
                            background: 'white',
                            fit: 490,
                            margin: [10, 10, 10, 10],
                        },
                    ],
                ],
            },
        },
        {
            image: 'scissorsHoriz',
            width: 20,
        },
        {
            style: 'qrTable',
            table: {
                body: [
                    [
                        {
                            qr: url,
                            foreground: qrColor,
                            background: 'white',
                            fit: 210,
                            margin: [10, 10, 10, 10],
                        },
                    ],
                ],
            },
            pageBreak: index !== qrUrls.length - 1 ? ('after' as PageBreak) : undefined,
        },
    ]);
    return {
        pageSize: 'A4',
        pageOrientation: 'portrait',
        pageMargins: [25, 25], // [horizontal, vertical]
        info: {
            title: docTitle,
            author: 'Delfar',
        },
        content: [...qrsContent],
        styles: {
            qrTable: {
                margin: [0, 10, 0, 10],
            },
        },
        images: {
            scissorsVert:
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAADe0lEQVRoge1ZO08VQRj9fF1UxGhM1BgLjfcmGh8JlV5ib0JMNFEIviottNSChALBjkYaSmj0D+AD1ESiBRUgamzUaEF8gCDGaFTwEfX72NnrMDvfzOze2bsWnOTkht2zZ85lHjvzXYAFLKCiWIHsQZ5KwZs8u5HLU/CewzLkAPIPchxZ49G7RniS913kUo/eJVwSDYTs8OjdoXhf9Og9B+rWz0oj35EFD94F4SV7f0JWefAuoag0ELLPg3cf473Xg3cJ+5lGiPVl+NYbfOvK8I1gFUSHUMjnyFwCz5x4VudJQ2hl2akVtDCNEZsT+DUb/Fo85I1gMfI+0yD1zqYYXhsg+C/rvAaRS7ylVrAZ+YFp+EoMn6uMx0fkFn9x9TjCNP4bgsluQ1FodR5NKeTVoocJMArBUONA94aYZ7tTzBtBNfIpE+S04bkzzDMvwO/WxAm7kTOaMJPINRr9auSERj+LrK1AXi0uaAIRL2u0nYz2fEWSMliEvKkJ9RO5S9LtQP7Q6O4Ij0yxHvRDY0DS3Nbcp6G2saJJDTgA+qXxEPKw5jppD2aS1ADdGH8pqF7vzCijEbR/fwj8/ibkE0jx2Fgu8sDvWolfkNszS+cI7mVle8n9V+iFaPjeTBPFAG2FH0P0CzyCFLfJPnEO+CF0NsNcTliLfA/8F6CzxLrM0jmgC+YHHhSUr3Vlls6CnRDsgdTTlXqK+4Xck01EM+4Bf7pST3HUK5lv4mQ0gv10pZ7ijlYsHQTL30nkLfg3SafF33R9TAr2DIITm4pqcS/UjUme0+LaFLIfeQI8LrlUu9St6zraTle1QuPiRe+NfLnhycC0LKpsc/Bsi+FHPbItaXjqQnl3+RXZKgxz4pPK4N8kzQiYa/t070FMT1ulg8VxpaF9jK6oNNho8DyW0DNRvUgue7datPKwuGHQ9afgyWJSMrCNw7ykHTfoplLwZCG/VW3l8ypJO2PQyZWJOJ6zDnkjiNMDBUn7xqCTqxdxPCcc8kYgzwHbj27tkvaaQSfXkOJ4Xrem1UBeMWhFKDK6OnBfhZpS8GRBa/aI0iCtDDS5cuKzHebXR4fBXp0eTeCZeFtBhuEP0Da+A7cfJ7YKrYvnOHjaTnA1/ZBDjuFDkHbYwbPs8CGoCxsgmEy0ylB95xUElQa6nuRVT8/Q2KZJ/xqC4fRWtNEAjsPmL5RuFNr32nEBAAAAAElFTkSuQmCC',
            scissorsHoriz:
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAACxMAAAsTAQCanBgAAANISURBVGhD7djJzwxBHMbxse9CJIg4ECTEkjhZ4i4RiYMl1hMHjhwkDl7cXLg4cuEfsC8J4eBkFxeEg9jXCLETfJ9kfkl1TfU20zPdh36ST9737bemuma6u+pX06hTp06dSmUg1uEM3uB386f+Xo8BqGym4wb+JbgNtatcNKiXCA3a9w4zUJnotrgGG+A39GEaBjd/7sZXWJu76PbtNBJLsAhDdSAuq+EOXi8IZSHcN7ER3Uh/7MJn2Ln0+z4MQktOwhru0YGE6EpY23M6UHAm4wrsHL5LGIZInsMapD2gup2srZ6FIrMSH2D9xzmCSL7A/ql7Pin6v9tZERkBDcrtN44mmk2I5CmsQZ4r8F4HOsxc3If1Gecn9mMUWnIc1nCvDiREs5O17eQZ6Icd+A7rL44W0cRp252F1OFihKLZSbOUtW13FhqP07B+4jzEMqRG05a/DuhK6F0PgW4rzU7u4OUJ1iBPluIV3H58mjJ3Iu15jGQKXiPUYZrLmI2k6IM4iL8I9SH631FMQlvRm7iOUOdGtZKuxg/nmKjoO4Sx8KMrqBrKbe+7Ba26HUe3k26LE9D6oGdCU9cp6LiqVWU+HsAfiNaGbbAyYwvcFdWnSnczdN6eR/P3YYQGplrJnd18umIHMAalZy0+IjTQEJUDc1Cp6Pm5itCAzWOsQKEp6t770/yZFi1glUvWIsycxyyUnqQi7A6SHuJf0LowGoVGt5QeSi31WjF1orc4C23206ZRXYmtsGlUU6Rb7fp0Dk21hdzKU6EFJXQicxOhhUzPgBaycfAzE/fgtveplInbCWZKJ6WEZp95SIr2tFlKiWOYgFzR5XNLiCybelExtwp5Zpbl0Orr9uP7hFzFnEoEe3EvNvUTcQHWT5zM5bTqHntRrzb1umrb4T9LIakbmmewxr3eUmo2ewTrM07iltLdqJSxqdeg4opCX3BT/wLWIM8V0PpQZLIWhXqzkbhfbOXZ1GtxKzppReFFtHzN2O6mfgO6Ea3i+mpR06mdS7/rw7NKIJLQl7tpm3rVOt3+cnc49GEugMaRGA0y69fruvfTnpVSokGlbepVK+lBrmx0W2hl1oOtSlELjW3qNUuUsvmuU6dOnaqm0fgP2/4M1Wau+zAAAAAASUVORK5CYII=',
        },
    };
}

export default function QrCodes({ qrUrls, userName }: { qrUrls: string[]; userName: string }) {
    const qrColor = '#000000';
    const fileName = `${userName}_Códigos-QR`;
    const pdf = createPdf(pdfQrs(qrUrls, qrColor, fileName));

    const downloadPdf = () => pdf.download(fileName);
    const printPdf = async () => {
        // pdf.print() // <== nativo pdf-make, simple pero abre otra ventana.
        // **** Imprimir en misma ventana app con PrintJS:
        const blobData = await getPdfBlob(pdf);
        const pdfDoc = URL.createObjectURL(blobData);
        const pdfsPrintConfig: Configuration = {
            printable: pdfDoc,
            type: 'pdf',
        };
        printJS(pdfsPrintConfig);
    };

    const btnStyle = {
        p: 1,
        mx: 2,
    };
    return (
        <Stack direction="row" justifyContent="space-around">
            <Button variant="contained" fullWidth onClick={downloadPdf} sx={btnStyle}>
                Descargar
            </Button>
            <Button variant="contained" fullWidth onClick={printPdf} sx={btnStyle}>
                Imprimir
            </Button>
        </Stack>
    );
}
