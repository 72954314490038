import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';

export function Title({ text, info }: { text: string; info?: string }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography id="title" variant="subtitle1" color="primary">
                {text}
            </Typography>
            {info && (
                <Tooltip
                    id="tooltip"
                    title={<Typography style={{ whiteSpace: 'pre-line' }}>{info}</Typography>}
                    placement="right">
                    <InfoIcon sx={{ ml: 2 }} color="disabled" fontSize="small" />
                </Tooltip>
            )}
        </Box>
    );
}
