import React, { useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Autocomplete, {
    AutocompleteInputChangeReason,
    AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import { Control, Controller } from 'react-hook-form-mui';
import { v4 as uuid } from 'uuid';
import { useGetSearchProducts } from '../services/api/queries/useGetSearchProducts';
import { FormValues } from '../Views/Orders/types/editableOfferCard.formValues';
import { ProductSearch } from '../services/api/dto/search.dto';

const QUERY_STR_LENGTH_TRESH = 2;
const QUERY_STR_LENGTH_LIMIT = 15;

type ProductSearchBarProps = {
    index: number;
    name: `products.${number}.name`;
    inputComponent: (params: AutocompleteRenderInputParams) => React.ReactNode;
    control: Control<FormValues>;
    disabled: boolean;
    setPrice: (index: number, price: number) => void;
};
export default function ProductSearchBar({
    index,
    name,
    control,
    inputComponent,
    disabled,
    setPrice,
}: ProductSearchBarProps) {
    const [queryParam, setQueryParam] = useState<string>('');
    const { products, productsById } = useGetSearchProducts(queryParam);
    const [keyId] = useState<string>(uuid());

    const handleInputChange = (
        event: React.SyntheticEvent<Element, Event>,
        newInputValue: string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        reason: AutocompleteInputChangeReason,
    ) => {
        // queryParam = definido dentro de rango para no realizar queries innecesarias
        if (
            newInputValue.length >= QUERY_STR_LENGTH_TRESH &&
            newInputValue.length <= QUERY_STR_LENGTH_LIMIT
        ) {
            setQueryParam(newInputValue.substring(0, QUERY_STR_LENGTH_LIMIT));
        }
    };
    const queryIsNumberID = (query: string): boolean => !Number.isNaN(Number(query));
    const getProductNameValue = (prod: ProductSearch | undefined): string | undefined => {
        if (!prod) return undefined;
        return `${prod.description} - ${prod.laboratory}`;
    };
    const getProductOptionLabelValue = (prod: ProductSearch | undefined): string | undefined => {
        if (!prod) return undefined;
        if (queryIsNumberID(queryParam)) {
            return `${prod.description} - ${prod.laboratory} - ${prod.id}`;
        }
        return `${prod.description} - ${prod.laboratory}`;
    };
    const getProductOptionRenderValue = (prod: ProductSearch): string => {
        let displayName = '';
        if (queryIsNumberID(queryParam)) {
            displayName = `${prod.id} - `;
        }
        displayName += `${prod.description}`;
        if (prod.laboratory) {
            displayName += ` - ${prod.laboratory}`;
        }
        return displayName;
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Autocomplete
                    key={`ProductSearchBar---Autocomplete---${keyId}`}
                    sx={{ width: '100%' }}
                    freeSolo
                    value={field.value}
                    onChange={(event, id) => {
                        const product = productsById[id];
                        if (product?.price) {
                            setPrice(index, product.price);
                        }
                        field.onChange(getProductNameValue(product) || id);
                    }}
                    disableClearable
                    onInputChange={handleInputChange}
                    options={
                        field.value.length >= QUERY_STR_LENGTH_TRESH
                            ? products.map((p) => p.id)
                            : []
                    }
                    getOptionLabel={(id) => {
                        const product = productsById[id];
                        return getProductOptionLabelValue(product) || id;
                    }}
                    renderInput={inputComponent}
                    disabled={disabled}
                    renderOption={(props, id, { inputValue: inVal }) => {
                        const product = productsById[id];
                        const renderValue = getProductOptionRenderValue(product);
                        const matches = match(renderValue, inVal, {
                            insideWords: true,
                        });
                        const parts = parse(renderValue, matches);
                        return (
                            <li {...props} key={`${id}---li---${keyId}`}>
                                <div key={`${id}---div---${keyId}`}>
                                    {parts.map((part, indx) => (
                                        <span
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={`${id}---span---${indx}---${keyId}`}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                                fontSize: 14,
                                            }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            </li>
                        );
                    }}
                />
            )}
        />
    );
}
