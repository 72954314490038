import {
    DocumentData,
    FirestoreDataConverter,
    QueryDocumentSnapshot,
    SnapshotOptions,
} from 'firebase/firestore';
import dayjs from 'dayjs';
import { DisclosedOrder, OfferProduct, Patient, PublicOrder, UserOffer } from '../../Models';

export const publicOrderConverter: FirestoreDataConverter<PublicOrder> = {
    toFirestore(): DocumentData {
        return {};
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): PublicOrder {
        const data = snapshot.data(options)!;
        return {
            id: snapshot.id,
            customer_id: data.customer_id,
            short_id: data.short_id,
            state: data.state,
            health_provider: data.health_provider,
            delivery_zone: data.delivery.zone,
            creation_timestamp: data.creation_timestamp.toDate(),
            creation_datetime: dayjs(data.creation_timestamp.toDate()).format('DD/MM/YYYY hh:mm A'), // todo borrar, no es necesario
            bidding_end: data.bidding_end?.toDate(),
            bidding_start: data.bidding_start?.toDate(),
            referrer_pharmacy_ids: data.referrer_pharmacy_ids || [],
        };
    },
};

export const userOfferConverter: FirestoreDataConverter<UserOffer> = {
    toFirestore(): DocumentData {
        return {};
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): UserOffer {
        const data = snapshot.data(options)!;
        const result: UserOffer = {
            id: snapshot.id,
            order_id: snapshot.ref.parent.parent!.id,
            pharmacy_id: data.pharmacy_id,
            state: data.state,
            scheduled_stock_datetime: data.scheduled_stock_datetime?.toDate(),
        };
        if (data.prescription_charged_price) {
            result.prescription_charged_price = data.prescription_charged_price / 100; // del server vienen en centavos
        }
        if (data.prescription_products?.length > 0 || data.products?.length > 0) {
            // todo pasar todo a data.products
            result.products = (data.prescription_products || data.products).map(
                (product: OfferProduct) => ({
                    name: product.name,
                    quantity: product.quantity,
                    unit_price: product.unit_price / 100, // del server vienen en centavos
                    discount_percent: product.discount_percent,
                }),
            );
        }
        return result;
    },
};

export const disclosedOrderConverter: FirestoreDataConverter<DisclosedOrder> = {
    toFirestore(): DocumentData {
        return {};
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): DisclosedOrder {
        const data = snapshot.data(options)!;
        const order = publicOrderConverter.fromFirestore(snapshot, options);
        const orderToReturn = {
            ...order,
            patient: data.patient, // todo esto creo no está bien
            assigned_pharmacy_id: data.assigned_pharmacy_id,
            hp_authentication: undefined,
        };
        const hpAuthentication = data.hp_authentication;
        if (hpAuthentication) {
            orderToReturn.hp_authentication = hpAuthentication;
        }
        return orderToReturn;
    },
};

export const patientConverter: FirestoreDataConverter<Patient> = {
    toFirestore(): DocumentData {
        return {};
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Patient {
        const data = snapshot.data(options)!;
        return {
            fullname: data.fullname,
            national_id: data.national_id,
            phone_number: data.phone_number,
            credential_url: data.credential_url,
            address: data.address,
        };
    },
};
