import React from 'react';
import InitDesktopNotifications from './InitDesktopNotifications';
import { MercadoPagoSetupDialog } from './MercadoPagoSetupDialog';

export default function InitialChecks() {
    return (
        <>
            <InitDesktopNotifications />
            <MercadoPagoSetupDialog />
        </>
    );
}
