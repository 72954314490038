import { ID } from './Firebase';

export type DeliveryState =
  // Estado inicial
  | 'estimated'
  | 'discarded'
  // camino feliz
  | 'requested'
  | 'on_route_to_pickup'
  | 'at_pickup'
  | 'on_route_to_dropoff'
  | 'at_dropoff'
  | 'delivered_at_dropoff'
  // casos para el retorno de la receta.
  | 'returning_to_pickup'
  | 'returned_to_pickup'
  | 'delivered_at_pickup'
  // casos de cancelación
  | 'internal_cancel'
  | 'archived';

export type Delivery = {
  id: ID;
  order_id: ID;
  pharmacy_id: ID;
  state: DeliveryState;
};

export const unconfirmed = (delivery: Delivery) =>
  ['estimated', 'requested'].includes(delivery.state);
export const headingToDropOff = (delivery: Delivery) =>
  ['on_route_to_dropoff', 'at_dropoff', 'delivered_at_dropoff'].includes(delivery.state);
export const headingToStore = (delivery: Delivery) =>
  ['on_route_to_pickup', 'returning_to_pickup'].includes(delivery.state);
export const atStore = (delivery: Delivery) =>
  // Queremos llamar la atención del usuario cuando el delivery está en la puerta del local o cuando está cerca
  ['at_pickup', 'returned_to_pickup'].includes(delivery.state);
