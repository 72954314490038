import { AlertColor } from '@mui/material/Alert';
import { useState } from 'react';

export const useAlertSnackbar = () => {
    const [alertState, setAlertState] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('warning' as AlertColor);
    const newAlert = (msg: string, severity: AlertColor) => {
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertState(true);
    };
    const closeAlert = () => {
        setAlertState(false);
    };

    return { alertState, alertMsg, alertSeverity, newAlert, closeAlert };
};
