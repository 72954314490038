import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retryDelay: (failureCount) => truncatedExponentialBackoff(failureCount),
            retry: 15,
        },
    },
});

export const truncatedExponentialBackoff = (n: number) => {
    // https://cloud.google.com/iot/docs/how-tos/exponential-backoff
    const randMillisecond = Math.random();
    const maximumBackoff = 128;
    return Math.min(2 ** n + randMillisecond, maximumBackoff) * 1000;
};
