import { collection, getFirestore, getDocs } from 'firebase/firestore';
import { healthProviderConverter } from './converters';
import { app } from '../firebase';
import { HealthProvider, HealthProviderId } from '../../Models';

const db = getFirestore(app);
export const getAllHealthProviders = () =>
    getDocs(collection(db, 'health_providers').withConverter(healthProviderConverter)).then(
        (querySnapshot) => {
            const resp: Record<HealthProviderId, HealthProvider> = {};
            querySnapshot.forEach((doc) => {
                if (doc.data()) resp[doc.id] = doc.data();
            });
            return resp;
        },
    );
