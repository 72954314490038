import Box from '@mui/material/Box';
import MopedIcon from '@mui/icons-material/Moped';
import Stack from '@mui/material/Stack/Stack';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Typography from '@mui/material/Typography';
import React from 'react';
import {
    atStore,
    Delivery,
    DisclosedOrder,
    headingToStore,
    inEmittedState,
    inProcessingState,
    isScheduled,
    isSchedulePending,
    needsHpAuthToken,
    PublicOrder,
    unconfirmed,
} from '../../../../Models';
import { Countdown } from '../../../../Components/Countdown';
import { HpAuthStateIcon } from './HpAuthStateIcon';

type SubStateIconProps = {
    order: PublicOrder | DisclosedOrder;
    delivery?: Delivery;
};

export function SubStateIcon({ order, delivery }: SubStateIconProps) {
    if (inEmittedState(order) && order.bidding_end && order.bidding_start) {
        // TODO este campo no está andando. Revisar
        return (
            <Box sx={{ color: order.user_offer ? 'info.main' : 'white' }}>
                <Countdown
                    color="inherit"
                    startTime={order.bidding_start}
                    endTime={order.bidding_end}
                />
            </Box>
        );
    }
    if (needsHpAuthToken(order)) {
        const orderDisclosed = { ...order } as DisclosedOrder;
        return <HpAuthStateIcon hpAuth={orderDisclosed?.hp_authentication} />;
    }
    if (inProcessingState(order)) {
        let icon: React.JSX.Element;
        let shortIdColor = '';
        if (order.state === 'approved' && isScheduled(order)) {
            if (isSchedulePending(order)) {
                shortIdColor = '';
            } else {
                shortIdColor = 'white';
            }
            icon = <CalendarMonthIcon color="disabled" />;
        } else if (order.state !== 'approved' && delivery) {
            shortIdColor = '';
            // Si approved, todavía nose pidió la moto.
            // Secondary cuando la moto está en el local.
            // Gris cuando está en tránsito.
            const storeDirection =
                unconfirmed(delivery) || headingToStore(delivery) || atStore(delivery);
            let color: 'disabled' | 'secondary' = 'disabled';
            if (atStore(delivery)) {
                color = 'secondary';
            }
            icon = (
                <MopedIcon
                    color={color}
                    sx={{ transform: storeDirection ? 'scaleX(-1)' : undefined }}
                />
            );
        } else {
            icon = <div />;
            shortIdColor = 'white';
        }
        return (
            <Stack direction="column" alignItems="center" spacing={1}>
                {icon}
                <Typography sx={{ color: shortIdColor }}>{order.short_id}</Typography>
            </Stack>
        );
    }
    return null;
}
