import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';

type CountdownProps = {
    startTime: Date;
    endTime: Date;
    color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit';
};
const refreshRate = 2500;
const percentageDifference = (endTime: Date, secondsToFinish: number) =>
    (new Date().getTime() - endTime.getTime()) / 1000 / secondsToFinish;

export function Countdown({ startTime, endTime, color }: CountdownProps) {
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => {
            const secondsToFinish = (startTime.getTime() - endTime.getTime()) / 1000;
            const diff = percentageDifference(endTime, secondsToFinish);
            setProgress((1 - (diff < 0 ? 0 : diff)) * 100);
        }, refreshRate);
        return () => clearInterval(intervalId);
    }, [endTime, startTime]);
    return <CircularProgress color={color} variant="determinate" value={progress} />;
}
