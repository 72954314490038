import Chip from '@mui/material/Chip';
import { OrderState } from '../Models';

type StateValues = {
    label: string;
    color:
        | 'info'
        | 'secondary'
        | 'primary'
        | 'warning'
        | 'default'
        | 'error'
        | 'success'
        | undefined;
};
export const stateMap = (state: OrderState): StateValues => {
    switch (state) {
        case 'emitted':
            return {
                label: 'Nueva',
                color: 'primary',
            };
        case 'assigned':
            return { label: 'Asignada', color: 'primary' };
        case 'validated':
            return { label: 'Validada', color: 'primary' };
        case 'approved':
            return {
                label: 'Listo para retirar',
                color: 'primary',
            };
        case 'ready_for_pickup':
            return {
                label: 'Listo para retirar',
                color: 'secondary',
            };
        case 'delivering':
            return {
                label: 'En tránsito',
                color: 'secondary',
            };
        case 'fulfilled':
            return {
                label: 'Entregado',
                color: 'success',
            };
        default:
            return {
                label: '------',
                color: 'warning',
            };
    }
};
export default function StatePill({ state }: { state: OrderState | undefined }) {
    return (
        <Chip
            label={state ? stateMap(state).label : '----'}
            color={state ? stateMap(state).color : 'warning'}
            size="small"
            sx={{
                width: '100%',
                align: 'center',
                maxWidth: '8vw',
            }}
        />
    );
}
