import { createContext, useEffect, useState } from 'react';
import { Delivery, ID } from '../../Models';
import deliveriesSubscription from './subscriptions';

const defaultState = {
    deliveries: {} as Record<ID, Delivery>,
};
export const DeliveriesContext = createContext(defaultState);
export const useDeliveries = (isSignedIn: boolean) => {
    const [deliveries, setDeliveries] = useState({} as Record<ID, Delivery>);

    useEffect(() => {
        if (!isSignedIn) return;
        deliveriesSubscription((ds) => {
            if (ds) {
                setDeliveries(ds);
            }
        });
    }, [isSignedIn]);

    return { deliveries };
};
