const useDesktopNotifications = () => {
    const showNotification = (title: string, body: string, onClick?: (s: any) => void) => {
        const notification = new Notification(title, {
            icon: '/favicon-rounded-borders.png?v=3',
            requireInteraction: true,
            image: '/logo-sin-iso-rojo-small.png?v=3',
            body,
        });
        if (onClick) {
            notification.onclick = onClick;
        }
    };
    const showNotificationWrapper = (title: string, body: string, onClick?: (s: any) => void) => {
        try {
            // por cuestiones de compatibilidad entre browser, puede que esta función falle
            showNotification(title, body, onClick);
        } catch (e) {
            console.error(e);
        }
    };
    return {
        showNotification: showNotificationWrapper,
    };
};
export default useDesktopNotifications;
