import axios from 'axios';

// https://dev.to/flexdinesh/cache-busting-a-react-app-22lk
// El resultado de ./meta.json NO se cachea. Esto es porque es XHR request de JSON.
// REACT_APP_VERSION SI se cachea porque viene compilado dentro del build.
type MetaFileContent = {
    appVersion: string;
};
export const getCurrentAppVersion = (): string => process.env.REACT_APP_VERSION || '0.0.0';
export const getLatestAppVersion = async () =>
    axios.get<MetaFileContent>('/meta.json').then((resp) => resp.data.appVersion);
export const isLatestAppVersion = async () => {
    const currentAppVersion = getCurrentAppVersion();
    const currentAppVersionParts = currentAppVersion.split('.');
    const latestAppVersion = await getLatestAppVersion();
    const latestAppVersionParts = latestAppVersion.split('.');
    return (
        currentAppVersionParts[0] === latestAppVersionParts[0] &&
        currentAppVersionParts[1] === latestAppVersionParts[1] &&
        currentAppVersionParts[2] === latestAppVersionParts[2]
    );
};
