import { filesApi } from './config/delfarApi';

export const getOrderDocumentsPdf = async (
    orderId: string,
): Promise<{ blobData: Blob | undefined; filename: string | undefined }> => {
    const response = await filesApi
        .get(`/orders/${orderId}/documents_pdf`, {
            responseType: 'blob',
        })
        .catch(() => undefined);
    if (!response) {
        return { blobData: undefined, filename: undefined };
    }

    const filename = response.headers['x-suggested-filename'];
    const blobData = new Blob([response.data], { type: 'application/pdf' });

    return { blobData, filename };
};
