import {
  FirestoreDataConverter,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';
import { Delivery } from '../../Models';

const deliveryConverter: FirestoreDataConverter<Delivery> = {
  toFirestore(delivery: Delivery): DocumentData {
    return delivery;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Delivery {
    const delivery = snapshot.data(options)!;
    return {
      id: snapshot.id,
      order_id: delivery.order_id,
      pharmacy_id: delivery.pharmacy_id,
      state: delivery.state,
    };
  },
};

export default deliveryConverter;
