import { IHeaderOverride } from '@cyntler/react-doc-viewer/dist/esm/models';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const HIGHLIGHT_COLOR = '#FD2458'; // Delfar red

// eslint-disable-next-line react/function-component-definition
export const HeaderBar: IHeaderOverride = (state, previousDocument, nextDocument) => {
    const { documents, currentFileNo } = state;
    return (
        <Box sx={{ backgroundColor: 'transparent' }}>
            <Stack direction="row" justifyContent="flex-end" spacing={1} alignItems="center">
                <Typography
                    id="image-count"
                    variant="body1"
                    fontWeight="bold"
                    fontSize={18}
                    color={
                        HIGHLIGHT_COLOR
                    }>{`${currentFileNo + 1} de ${documents.length}`}</Typography>
                <Stack direction="row" justifyContent="flex-end">
                    <IconButton
                        id="previous-button"
                        onClick={previousDocument}
                        disabled={currentFileNo === 0 || documents.length <= 1}
                        sx={{ color: HIGHLIGHT_COLOR }}>
                        <ArrowCircleLeftIcon fontSize="large" />
                    </IconButton>
                    <IconButton
                        id="next-button"
                        onClick={nextDocument}
                        disabled={currentFileNo >= documents.length - 1 || documents.length <= 1}
                        sx={{ color: HIGHLIGHT_COLOR }}>
                        <ArrowCircleRightIcon fontSize="large" />
                    </IconButton>
                </Stack>
            </Stack>
        </Box>
    );
};
