import { sessionsApi } from './config/delfarApi';
import {
    CreateSessionResponse,
    PollResponse,
    DeleteSessionResponse,
    RegisterFcmTokenResponse,
} from './dto/sessions.dto';

const createSession = async (): Promise<string> =>
    sessionsApi.post<CreateSessionResponse>('', undefined).then((r) => r.data.session_id);

const poll = async (sessionId: string): Promise<boolean> =>
    sessionsApi.patch<PollResponse>(`/${sessionId}`, undefined).then((r) => r.data);

const registerPollingEnd = async (sessionId: string): Promise<boolean> =>
    sessionsApi.delete<DeleteSessionResponse>(`/${sessionId}`).then((r) => r.data);

const sendRegistrationToken = async (
    sessionId: string,
    registrationToken: string,
): Promise<boolean> =>
    sessionsApi
        .patch<RegisterFcmTokenResponse>(`/${sessionId}/fcm-token`, {
            fcm_token: registrationToken,
        })
        .then((r) => r.data);
export default {
    createSession,
    poll,
    registerPollingEnd,
    sendRegistrationToken,
};
