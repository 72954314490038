import ReCAPTCHA from 'react-google-recaptcha';
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Unstable_Grid2';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

const recaptchaV2SiteKey = process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY || '';

export default function LogIn() {
    const [validCaptcha, setValidCaptcha] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const onCaptchaChange = (value: string | null) => {
        setValidCaptcha(!!value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        setLoading(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        try {
            await signInWithEmailAndPassword(
                getAuth(),
                data.get('email')?.toString() || '',
                data.get('password')?.toString() || '',
            );
        } catch (e) {
            console.error('LogIn error: ', e);
            setLoading(false);
            setError(true);
        }
    };

    return (
        <Grid
            container
            component="main"
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            }}>
            <Grid
                xs={false}
                sm={false}
                md={7}
                sx={{
                    backgroundImage: 'url(/store-banner.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid
                xs={12}
                sm={8}
                md={5}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    px: { xs: 3, sm: 5, md: 8 },
                }}>
                <Grid sm={2} md={false} sx={{ mt: { xs: 15, sm: 25, md: 10 } }}>
                    <img src="/android-chrome-192x192.png" alt="Delfar title" width={150} />
                </Grid>
                <Box
                    id="login-form"
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <Box
                        id="wrong-combination-error-msg"
                        sx={{ visibility: error ? 'visible' : 'hidden' }}>
                        <Typography variant="body1" color="error">
                            Usuario y/o contraseña incorrectos
                        </Typography>
                    </Box>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            mt: 2,
                        }}>
                        <ReCAPTCHA sitekey={recaptchaV2SiteKey} onChange={onCaptchaChange} />
                    </Box>
                    <LoadingButton
                        loading={loading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={!validCaptcha}
                        id="submit">
                        Iniciar Sesión
                    </LoadingButton>
                </Box>
                <Box
                    sx={{
                        mt: 3,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <Link href="https://delfar.com.ar/farmacias/" variant="body2">
                        No tenés cuenta? Asociate a Delfar
                    </Link>
                </Box>
            </Grid>
        </Grid>
    );
}
