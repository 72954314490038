import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { InputState } from '../InputState';

type Option = {
    title: string;
    tooltip: string;
    scheduledEnabled: boolean;
};
const CONFIRM_STOCK: Option = {
    title: 'Confirmar stock',
    tooltip:
        'Al confirmar stock, se comparará tu distancia con la de otras farmacias cercanas al paciente y comenzará la cuenta regresiva.',
    scheduledEnabled: false,
};
const SCHEDULED_STOCK: Option = {
    title: 'Programar stock',
    tooltip:
        'En caso de falta de stock, podés pedir en droguería y programar la entrega para otro momento.',
    scheduledEnabled: true,
};

function EmittedSubmitButton({
    width,
    setScheduleEnabled,
    inputState,
}: {
    width: number;
    setScheduleEnabled: (scheduledEnabled: boolean) => void;
    inputState: InputState;
}) {
    const handleScheduleButton = () => {
        setScheduleEnabled(true);
    };

    return (
        <>
            {/* SCHEDULE BUTTON */}
            <Tooltip
                disableHoverListener={inputState === 'disabled'}
                title={SCHEDULED_STOCK.tooltip}
                enterDelay={1000}
                placement="top">
                <Button
                    onClick={handleScheduleButton}
                    size="medium"
                    variant="outlined"
                    sx={{ width }}
                    disabled={inputState === 'disabled'}
                    color={inputState === 'edit' ? 'secondary' : 'primary'}>
                    {SCHEDULED_STOCK.title}
                </Button>
            </Tooltip>

            {/* CONFIRM BUTTON */}
            <Tooltip
                disableHoverListener={inputState === 'disabled'}
                title={CONFIRM_STOCK.tooltip}
                enterDelay={1000}
                placement="top">
                <Button
                    type="submit"
                    size="medium"
                    variant="contained"
                    sx={{ width }}
                    disabled={inputState === 'disabled'}
                    color={inputState === 'edit' ? 'secondary' : 'primary'}>
                    {CONFIRM_STOCK.title}
                </Button>
            </Tooltip>
        </>
    );
}

export { EmittedSubmitButton };
