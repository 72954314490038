import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { User } from '../../Models';

const listIconStyle = {
    minWidth: 0,
    mr: 2,
};

export function UserProfile({ user }: { user: User }) {
    return (
        <Stack
            direction="column"
            spacing={2}
            justifyContent="space-between"
            alignItems="flex-start">
            <Stack>
                <Typography id="user-name" variant="h4">
                    {user?.name}
                </Typography>
                <List dense>
                    <ListItem key={user?.email} id="user-email">
                        <ListItemIcon sx={listIconStyle}>
                            <MailOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary={user?.email} />
                    </ListItem>
                    <ListItem key={user?.contact_number} id="user-contact-number">
                        <ListItemIcon sx={listIconStyle}>
                            <WhatsAppIcon />
                        </ListItemIcon>
                        <ListItemText primary={user?.contact_number} />
                    </ListItem>
                    <ListItem key={user?.address} id="user-address">
                        <ListItemIcon sx={listIconStyle}>
                            <LocationOnIcon />
                        </ListItemIcon>
                        <ListItemText primary={user?.address} />
                    </ListItem>
                </List>
            </Stack>
        </Stack>
    );
}
