import { useMemo, useState, useCallback, useEffect } from 'react';
import { DocRenderer } from '@cyntler/react-doc-viewer';
// eslint-disable-next-line import/no-extraneous-dependencies
import { styled } from '@mui/system'; // no la toma eslint pero si esta
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import axios from 'axios';

// eslint-disable-next-line react/function-component-definition
const ImageProxyRenderer: DocRenderer = (props) => {
    const {
        mainState: { currentDocument },
        children,
    } = props;
    const scaleUp = false;
    const zoomFactor = 9;

    const [container, setContainer] = useState<HTMLDivElement | null>(null);

    const [containerWidth, setContainerWidth] = useState<number>(0);
    const [containerHeight, setContainerHeight] = useState<number>(0);

    const [imageNaturalWidth, setImageNaturalWidth] = useState<number>(0);
    const [imageNaturalHeight, setImageNaturalHeight] = useState<number>(0);

    const imageScale = useMemo(() => {
        if (
            containerWidth === 0 ||
            containerHeight === 0 ||
            imageNaturalWidth === 0 ||
            imageNaturalHeight === 0
        )
            return 0;
        const scale = Math.min(
            containerWidth / imageNaturalWidth,
            containerHeight / imageNaturalHeight,
        );
        return scaleUp ? scale : Math.max(scale, 1);
    }, [scaleUp, containerWidth, containerHeight, imageNaturalWidth, imageNaturalHeight]);

    const handleResize = useCallback(() => {
        if (container !== null) {
            const rect = container.getBoundingClientRect();
            setContainerWidth(rect.width);
            setContainerHeight(rect.height);
        } else {
            setContainerWidth(0);
            setContainerHeight(0);
        }
    }, [container]);

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    const handleImageOnLoad = (image: HTMLImageElement) => {
        setImageNaturalWidth(image.naturalWidth);
        setImageNaturalHeight(image.naturalHeight);
    };

    useEffect(() => {
        if (currentDocument) {
            const image = new Image();
            image.onload = () => handleImageOnLoad(image);
            image.src = currentDocument.fileData as string;
        }
    }, [currentDocument]);

    if (!currentDocument) return null;
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'transparent',
            }}
            ref={(el: HTMLDivElement | null) => setContainer(el)}>
            {children ||
                (imageScale > 0 && (
                    <TransformWrapper
                        wheel={{ step: 0.05 }}
                        key={`${containerWidth}x${containerHeight}`}
                        initialScale={imageScale}
                        minScale={imageScale}
                        maxScale={imageScale * zoomFactor}
                        centerOnInit>
                        <TransformComponent
                            wrapperStyle={{
                                width: '100%',
                                height: '100%',
                            }}>
                            <Img id="image-img" src={currentDocument.fileData as string} />
                        </TransformComponent>
                    </TransformWrapper>
                ))}
        </div>
    );
};

ImageProxyRenderer.fileTypes = [];
ImageProxyRenderer.weight = 0;
ImageProxyRenderer.fileLoader = ({ documentURI, fileLoaderComplete }) =>
    axios.get(documentURI, { responseType: 'blob' }).then((resp) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(resp.data);
        fileReader.onloadend = () => {
            fileLoaderComplete(fileReader);
        };
    });

const Img = styled('img')({
    maxWidth: '99%',
    maxHeight: '99%',
});

// eslint-disable-next-line react/function-component-definition
const JPGRenderer: DocRenderer = (props) => <ImageProxyRenderer {...props} />;

JPGRenderer.fileTypes = ['jpg', 'jpeg', 'image/jpg', 'image/jpeg'];
JPGRenderer.weight = 0;

const StyledImageRenderer = styled(ImageProxyRenderer)`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: yellow;
    background-image: linear-gradient(45deg, blue 25%, transparent 25%),
        linear-gradient(-45deg, blue 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, blue 75%),
        linear-gradient(-45deg, transparent 75%, blue 75%);
    background-size: 20px 20px;
    background-position:
        0 0,
        0 10px,
        10px -10px,
        -10px 0px;
`;

// eslint-disable-next-line react/function-component-definition
const PNGRenderer: DocRenderer = (props) => <StyledImageRenderer {...props} />;

PNGRenderer.fileTypes = ['png', 'image/png'];
PNGRenderer.weight = 0;

export { PNGRenderer, JPGRenderer };
