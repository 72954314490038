import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import { Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack/Stack';
import Grid2 from '@mui/material/Unstable_Grid2';
import { FilesCard } from './FilesCard';
import { EditableOfferCard } from '../EditableOfferCard/EditableOfferCard';
import { OfferCard } from './OfferCard';
import {
    DisclosedOrder,
    inEmittedState,
    inProcessingState,
    inValidatingState,
    isDisclosedOrder,
    isScheduled,
    PublicOrder,
} from '../../../../../Models';
import { PatientCard } from './PatientCard';
import { InputState } from '../InputState';
import { ScheduleCard } from './ScheduleCard';
import { usePrescriptionData } from '../../../../../services/api/queries/usePrescriptionData';

type OrderDialogContentProps = {
    order: PublicOrder | DisclosedOrder;
    inputState: InputState;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    onDone: () => void;
};
export default function OrderDialogContent({
    order,
    inputState,
    setLoading,
    onDone,
}: OrderDialogContentProps) {
    const {
        isLoading: prescriptionsLoading,
        hasOnlyElectronicPrescriptionData,
        prescriptionFilesUrls,
        credentialUrl,
        printableFilesUrl,
    } = usePrescriptionData(order);
    /**
     * - únicamente se OCULTAN los documentos en emitted:
     * -- si y sólo si TODAS las recetas son electrónicas CON prescription_data
     */
    const hideFiles = inEmittedState(order) && hasOnlyElectronicPrescriptionData;
    if (isDisclosedOrder(order) && credentialUrl) {
        prescriptionFilesUrls.push(credentialUrl);
    }
    return (
        <DialogContent>
            <Grid2 container sx={{ mt: 1, height: 1 }} spacing={1}>
                <Grid2 spacing={1} xs height={1}>
                    <Stack spacing={1} height={1}>
                        {isScheduled(order) && <ScheduleCard order={order} />}
                        {isDisclosedOrder(order) && <PatientCard order={order} />}
                        {(inProcessingState(order) || order.state === 'fulfilled') && (
                            <OfferCard order={order} />
                        )}
                        {(inEmittedState(order) || inValidatingState(order)) &&
                            prescriptionsLoading && (
                                <Skeleton variant="rectangular" width="100%" height="100%" />
                            )}
                        {(inEmittedState(order) || inValidatingState(order)) &&
                            !prescriptionsLoading && (
                                <EditableOfferCard
                                    order={order}
                                    inputState={inputState}
                                    setLoading={setLoading}
                                    onDone={onDone}
                                />
                            )}
                    </Stack>
                </Grid2>
                {!prescriptionsLoading && !hideFiles && (
                    <Grid2 xs={5} height={1}>
                        <FilesCard
                            individualFiles={prescriptionFilesUrls}
                            printableFilesUrl={printableFilesUrl}
                            order={order}
                        />
                    </Grid2>
                )}
            </Grid2>
        </DialogContent>
    );
}
