import { useContext, useRef, useState } from 'react';
import Card from '@mui/material/Card/Card';
import Stack from '@mui/material/Stack/Stack';
import Divider from '@mui/material/Divider/Divider';
import CardContent from '@mui/material/CardContent/CardContent';
import Skeleton from '@mui/material/Skeleton';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import SettingIcon from '@mui/icons-material/Settings';
import { useNavigate, useParams } from 'react-router-dom';
import OrderPreview from './Components/OrderPreview';
import { OrdersContext } from '../../services/Orders';
import Title from '../../Components/Title';
import {
    Delivery,
    DisclosedOrder,
    headingToDropOff,
    isOTC,
    isPast,
    isSchedulePending,
    PublicOrder,
    shouldBeShown,
} from '../../Models';
import { DeliveriesContext } from '../../services/Deliveries';
import OrderDialog from './Components/OrderDialog';
import { LocalConfigContext } from '../../hooks/useLocalConfig';
import ColumnConfig from './Components/ColumnConfig.';
import { UserContext } from '../../services/User';
import AssignedOrderNotifications from './Components/AssignedOrderNotifications';

const cardStyle = { width: '100%', px: 0, py: 1 };
const cardHeaderStyle = { px: 2, pt: 0, pb: 0 };
const columnStyle = { maxHeight: '100%', overflow: 'auto', px: 2, pb: 3, pt: 0 };

function CustomDivider() {
    return <Divider sx={{ mb: 2 }} />;
}

const requestDeliveryAutomaticallyTooltip = `Al habilitar esta opción, el delivery se pedirá automáticamente cuando el paciente abone el pedido.

Cuando el pedido es programado, el delivery será programado automáticamente para la fecha y el horario que estableciste.  

Recordá: siempre por 
`;
function CustomSkeleton({ height }: { height: number }) {
    return (
        <Skeleton style={{ borderRadius: 5 }} variant="rectangular" width="100%" height={height} />
    );
}

const skip = (order: PublicOrder, delivery: Delivery) =>
    // puede ocurrir que delivery cuando si se updatea el estado del delivery antes que el del pedido
    isPast(order) || !delivery || (isOTC(order) && headingToDropOff(delivery));
const approvedOrderSortFunction = (a: DisclosedOrder, b: DisclosedOrder) => {
    const aIsSchedulePending = isSchedulePending(a);
    const bIsSchedulePending = isSchedulePending(b);
    if (
        (aIsSchedulePending && bIsSchedulePending) ||
        (!aIsSchedulePending && !bIsSchedulePending)
    ) {
        return a.creation_timestamp.getTime() - b.creation_timestamp.getTime();
    }
    if (aIsSchedulePending) {
        return 1;
    }
    return -1;
};

export default function Kanban() {
    const selectedOrder = useRef<undefined | PublicOrder | DisclosedOrder>(undefined);
    const { user } = useContext(UserContext);
    const { emitted, assigned, approved, loading } = useContext(OrdersContext);
    const { deliveries } = useContext(DeliveriesContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const [showConfig, setShowConfig] = useState(false);
    const [configMode, setConfigMode] = useState(false);
    const { requestDeliveryAutomatically, toggleRequestDeliveryAutomatically } =
        useContext(LocalConfigContext);
    const openOrderDialog = (orderId: string) => navigate(`/orders/${orderId}`, { replace: true });
    const closeOrderDialog = () => navigate('/orders', { replace: true });
    selectedOrder.current = [...emitted, ...assigned, ...approved].find((o) => o.id === id);

    return (
        <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={3}
            justifyContent="space-between"
            sx={{ height: '100%', p: 0, m: 0 }}
            id="orders-kanban">
            <Card id="new-orders-column" sx={cardStyle}>
                <CardHeader title={<Title text="Nuevos" />} sx={cardHeaderStyle} />
                <CardContent sx={columnStyle}>
                    <CustomDivider />
                    {/* <ProductSearchBar /> */}
                    <Stack spacing={2} direction={{ xs: 'row', sm: 'column' }}>
                        {loading && <CustomSkeleton height={70} />}
                        {emitted
                            .filter((o) => shouldBeShown(o, user.id))
                            .map((order) => (
                                <OrderPreview
                                    key={order.id}
                                    onClick={() => openOrderDialog(order.id)}
                                    order={order}
                                />
                            ))}
                    </Stack>
                </CardContent>
            </Card>
            <Card id="assigned-orders-column" sx={cardStyle}>
                <CardHeader title={<Title text="Facturar" />} sx={cardHeaderStyle} />
                <CardContent sx={columnStyle}>
                    <CustomDivider />
                    <Stack spacing={2} direction={{ xs: 'row', sm: 'column' }}>
                        {loading && <CustomSkeleton height={90} />}
                        {assigned.map((order) => (
                            <>
                                <AssignedOrderNotifications order={order} />
                                <OrderPreview
                                    key={order.id}
                                    onClick={() => openOrderDialog(order.id)}
                                    order={order}
                                    delivery={deliveries[order.id]}
                                />
                            </>
                        ))}
                    </Stack>
                </CardContent>
            </Card>
            <Card id="processing-orders-column" sx={cardStyle}>
                <CardHeader
                    title={<Title text="Abonados" />}
                    sx={cardHeaderStyle}
                    action={
                        <IconButton
                            sx={{ mt: 0.5 }}
                            size="small"
                            onClick={() => setConfigMode(!configMode)}
                            onMouseOver={() => setShowConfig(true)}
                            onMouseOut={() => setShowConfig(false)}>
                            <SettingIcon
                                fontSize="small"
                                sx={{ visibility: showConfig ? 'visible' : 'hidden' }}
                            />
                        </IconButton>
                    }
                />
                <CardContent sx={columnStyle}>
                    <ColumnConfig
                        isOpen={configMode}
                        tooltipTitle={requestDeliveryAutomaticallyTooltip}
                        switchChecked={requestDeliveryAutomatically}
                        switchOnChange={toggleRequestDeliveryAutomatically}
                        label="Pedir delivery automáticamente"
                    />
                    <CustomDivider />
                    <Stack spacing={2} direction={{ xs: 'row', sm: 'column' }}>
                        {loading && <CustomSkeleton height={80} />}
                        {approved
                            .filter((order) => !skip(order, deliveries[order.id]))
                            .sort(approvedOrderSortFunction)
                            .map((order) => (
                                <OrderPreview
                                    key={order.id}
                                    onClick={() => openOrderDialog(order.id)}
                                    order={order}
                                    delivery={deliveries[order.id]}
                                />
                            ))}
                    </Stack>
                </CardContent>
            </Card>
            {selectedOrder.current && (
                <OrderDialog
                    order={selectedOrder.current}
                    closeOrder={closeOrderDialog}
                    open={!!selectedOrder.current}
                />
            )}
        </Stack>
    );
}
