import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Control, TextFieldElement, UseFieldArrayReturn } from 'react-hook-form-mui';
import { v4 as uuid } from 'uuid';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { AutocompleteRenderInputParams, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Product, PublicOrder } from '../../../../../Models';
import { FormValues, OfferProductFormValues } from '../../../types/editableOfferCard.formValues';
import { InputState } from '../InputState';
import ProductSearchBar from '../../../../../Components/ProductSearchBar';
import { useGetSearchProducts } from '../../../../../services/api/queries/useGetSearchProducts';

const subHeaderStyle = {
    borderRadius: 2,
    py: 0.7,
    px: 1,
    backgroundColor: '#F5F5F5',
};
const chargedSubTotalStyle = {
    width: '9rem',
    backgroundColor: 'white',
};

function OtcInput({
    inputState,
    otcProducts,
    canSetOTCPrice,
}: {
    inputState: InputState;
    otcProducts: Product[];
    canSetOTCPrice: boolean;
}) {
    return (
        <Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                sx={subHeaderStyle}>
                <Typography fontSize={18}>Venta libre:</Typography>
                <TextFieldElement
                    sx={chargedSubTotalStyle}
                    required
                    size="small"
                    variant="outlined"
                    name="otc_charged_price"
                    type="number"
                    label="Total"
                    disabled={inputState === 'disabled' || !canSetOTCPrice}
                    parseError={() => ''}
                    validation={{ min: 0 }}
                    InputProps={{
                        inputProps: {
                            style: { textAlign: 'right' },
                        },
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                />
            </Stack>
            <Stack direction="column" justifyContent="space-between" alignItems="left" spacing={1}>
                <List>
                    {otcProducts
                        .filter((p) => !!p.name)
                        .map((prod) => (
                            <ListItem key={prod.name} dense>
                                {prod.name}
                            </ListItem>
                        ))}
                </List>
            </Stack>
        </Stack>
    );
}

const createEmptyItem = (): OfferProductFormValues => ({
    discount_percent: undefined,
    name: '',
    quantity: 1,
    unit_price: undefined,
});
type PrescriptionInputProps = {
    order: PublicOrder;
    disabled: boolean;
    control: Control<FormValues>;
    productsForm: UseFieldArrayReturn<FormValues, 'products'>;
};
function PrescriptionInput({ order, disabled, productsForm, control }: PrescriptionInputProps) {
    const { fields, remove, append, update } = productsForm;
    useEffect(() => {
        if (!fields.length) {
            append(createEmptyItem());
        }
    }, [append, fields.length]);
    const addListItem = () => append(createEmptyItem());

    const getName = (index: number) => fields[index].name;
    const getPrice = (index: number) => fields[index].unit_price;
    const setPrice = (index: number, price: number) => {
        update(index, { ...fields[index], unit_price: price });
    };

    return (
        <Stack direction="column" alignItems="center" spacing={1}>
            <List sx={{ width: 1 }}>
                {fields.map((field, i) => (
                    <ProductItem
                        key={`ProductItem---${field.id}`}
                        index={i}
                        onClickRemoveItem={() => remove(i)}
                        disabledInputs={disabled}
                        control={control}
                        getName={getName}
                        getPrice={getPrice}
                        setPrice={setPrice}
                    />
                ))}
            </List>
            {order.state !== 'validated' && (
                <Tooltip title="Agregar item" enterDelay={2000}>
                    <Button
                        aria-label="add"
                        size="small"
                        variant="contained"
                        onClick={addListItem}
                        sx={{
                            width: '6rem',
                        }}>
                        <AddCircleIcon />
                    </Button>
                </Tooltip>
            )}
        </Stack>
    );
}

type ProductItemProps = {
    onClickRemoveItem: () => void;
    index: number;
    disabledInputs: boolean;
    control: Control<FormValues>;
    getName: (index: number) => string | undefined;
    getPrice: (index: number) => number | undefined;
    setPrice: (index: number, price: number) => void;
};
function ProductItem({
    onClickRemoveItem,
    index,
    disabledInputs,
    control,
    getName,
    getPrice,
    setPrice,
}: ProductItemProps) {
    const [keyId] = useState<string>(uuid());
    const inputsMx = 1;
    const FONT_SIZE = 14;
    const inputStyleName = { width: '100%' };
    const inputStyleQty = { mx: inputsMx, width: '7rem' };
    const inputStylePercent = { mx: inputsMx, width: '7rem' };
    const inputStylePrice = { mx: inputsMx, width: '8rem' };
    const namePrefix = `products.${index}`;
    const canDeletePrescProdItem = true;
    const defaultName = getName(index);
    const defaultPrice = getPrice(index);
    const { products: matchingProducts } = useGetSearchProducts(defaultName);

    useEffect(() => {
        if (defaultPrice === undefined) {
            if (matchingProducts.length === 1) {
                setPrice(index, matchingProducts[0].price);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchingProducts.length]);

    const searchInput = (params: AutocompleteRenderInputParams) => (
        <Tooltip title="Podés buscar por código o nombre del producto" enterDelay={2000}>
            <span>
                <TextFieldElement
                    {...params}
                    required
                    type="string"
                    name={`${namePrefix}.name`}
                    label="Nombre"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    size="small"
                    variant="standard"
                    disabled={disabledInputs}
                    parseError={() => ''}
                    sx={inputStyleName}
                />
            </span>
        </Tooltip>
    );

    return (
        <ListItem
            key={`ProductItem---ListItem---${index}---${keyId}`}
            sx={{
                px: 0,
                py: 1,
                ':hover': {
                    backgroundColor: '#edf6fc',
                },
            }}>
            <ProductSearchBar
                index={index}
                name={`products.${index}.name`}
                inputComponent={searchInput}
                control={control}
                disabled={disabledInputs}
                setPrice={setPrice}
            />

            <TextFieldElement
                required
                type="number"
                name={`${namePrefix}.quantity`}
                label="Cant."
                InputLabelProps={{
                    shrink: true,
                }}
                size="small"
                variant="standard"
                disabled={disabledInputs}
                parseError={() => ''}
                validation={{ min: 1 }}
                sx={inputStyleQty}
                InputProps={{
                    inputProps: {
                        style: { fontSize: FONT_SIZE, textAlign: 'right' },
                    },
                }}
            />
            <TextFieldElement
                required
                type="number"
                name={`${namePrefix}.discount_percent`}
                label="Desc."
                size="small"
                variant="standard"
                disabled={disabledInputs}
                parseError={() => ''}
                validation={{ min: 0, max: 100 }}
                sx={inputStylePercent}
                InputProps={{
                    inputProps: {
                        style: { fontSize: FONT_SIZE, textAlign: 'right' },
                    },
                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
            />
            <Tooltip
                title="Precio unitario del producto, sin el descuento aplicado"
                enterDelay={2000}>
                <span>
                    <TextFieldElement
                        required
                        type="number"
                        name={`${namePrefix}.unit_price`}
                        label="Precio u. sin desc."
                        size="small"
                        variant="standard"
                        disabled={disabledInputs}
                        parseError={() => ''}
                        validation={{ min: 0 }}
                        sx={inputStylePrice}
                        InputProps={{
                            inputProps: {
                                style: { fontSize: FONT_SIZE, textAlign: 'right' },
                            },
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                    />
                </span>
            </Tooltip>
            {!disabledInputs && (
                <Tooltip title="Eliminar item" enterDelay={2000}>
                    <span>
                        <IconButton
                            sx={{ p: 1 }}
                            aria-label="delete"
                            size="small"
                            onClick={onClickRemoveItem}
                            disabled={!canDeletePrescProdItem}>
                            <DeleteIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            )}
        </ListItem>
    );
}

export { OtcInput, PrescriptionInput };
