import dayjs from 'dayjs';
import { Product, UserOffer } from '../../../Models';
import { FormValues, OfferProductFormValues } from '../types/editableOfferCard.formValues';
import { capitalizeEachWord } from '../../../utils/utils';

export const getInitFormValues = (
    offer: UserOffer | undefined,
    electronicProducts: {
        name: string;
        quantity: number;
        presentation?: string;
    }[],
    otcProducts: Product[],
): FormValues => {
    const products: OfferProductFormValues[] = [];
    if (!offer) {
        return {
            products,
            scheduled_stock_datetime: dayjs().set('minutes', 0).set('seconds', 0).add(1, 'hour'),
        };
    }
    if (!offer.products?.length) {
        if (electronicProducts?.length) {
            products.push(
                ...electronicProducts.map((ep) => ({
                    name: capitalizeEachWord(ep.name),
                    quantity: ep.quantity,
                    unit_price: undefined,
                    discount_percent: 0,
                })),
            );
        }
        if (otcProducts?.length) {
            products.push(
                ...otcProducts.map((ep) => ({
                    name: ep.name || '',
                    quantity: 1,
                    unit_price: undefined,
                    discount_percent: 0,
                })),
            );
        }
    } else {
        products.push(
            ...(offer.products?.map((product) => ({
                name: product.name,
                unit_price: product.unit_price,
                discount_percent: product.discount_percent,
                quantity: product.quantity,
            })) || []),
        );
    }

    return {
        scheduled_stock_datetime: dayjs(offer.scheduled_stock_datetime),
        products,
    };
};
