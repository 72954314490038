import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';

export function SaveButton({ enabled, saving }: { enabled: boolean; saving: boolean }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
            }}>
            <LoadingButton
                variant="contained"
                disabled={!enabled}
                loading={saving}
                type="submit"
                color="success">
                Guardar
            </LoadingButton>
        </Box>
    );
}
