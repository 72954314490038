import Typography, { TypographyProps } from '@mui/material/Typography';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';

type CopyTypographyProps = TypographyProps & {
    value: string | undefined;
    // eslint-disable-next-line react/require-default-props
    message?: string;
};

export function CopyTypography(props: CopyTypographyProps) {
    const [open, setOpen] = useState(false);
    const { value, children, message } = props;

    const handleClick = () => {
        setOpen(true);
        navigator.clipboard.writeText(value || '');
    };

    return (
        <>
            <Typography onClick={handleClick} {...props}>
                <Button
                    onClick={handleClick}
                    /* endIcon={<ContentCopy/>} */
                    size="small"
                    variant="text"
                    sx={{
                        m: 0,
                        p: 0,
                        fontWeight: 'inherit',
                        fontSize: 'inherit',
                        textAlign: 'inherit',
                        textTransform: 'none',
                    }}>
                    {children}
                </Button>
            </Typography>
            <Snackbar
                open={open}
                onClose={() => setOpen(false)}
                autoHideDuration={1500}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                message={message || 'Copiado'}
            />
        </>
    );
}
