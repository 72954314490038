import { useCopyToClipboard } from 'usehooks-ts';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { Skeleton } from '@mui/material';
import IconButton from '@mui/material/IconButton/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QrCodes from './Components/QrCodes';
import { User } from '../../Models';
import { useGetQrsAndReferrals } from '../../services/api/queries/useGetQrsAndReferrals';

export function Referrals({ user }: { user: User }) {
    const { qrUrls, referralUrls, isFetched } = useGetQrsAndReferrals();
    const refUrls = referralUrls.map((ref) =>
        ref.by_code.replace('www.', '').replace('https://', 'www.'),
    );
    const url = refUrls.at(0);
    const qrUrl = qrUrls.at(0);
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
    const [_, copy] = useCopyToClipboard();
    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center" height="6vh">
            <Grid xs={6} height="100%">
                {!isFetched && <Skeleton variant="rectangular" width="100%" height="100%" />}

                {isFetched && url && (
                    <Stack direction="row" alignItems="center">
                        <Tooltip title="Copiar link" placement="left">
                            <IconButton onClick={() => copy(url)}>
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                        {url}
                    </Stack>
                )}
            </Grid>
            <Grid xs={6} height="100%">
                {!isFetched && <Skeleton variant="rectangular" width="100%" height="100%" />}

                {isFetched && qrUrl && (
                    <Stack>
                        <QrCodes qrUrls={[qrUrl]} userName={user.name} />
                    </Stack>
                )}
            </Grid>
        </Grid>
    );
}
