import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

export const defaultTheme = createTheme({
    palette: {
        background: {
            default: grey[100],
        },
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '1em',
                    color: 'white',
                },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 3,
            },
        },
    },
});
