import { useLocation, useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import { LoadingButton } from '@mui/lab';
import { UserContext } from '../../services/User';

export function MercadoPagoSetupDialog() {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, loading } = useContext(UserContext);
    const goToConfig = () => {
        navigate('/dashboard', {});
    };

    if (
        loading ||
        user.mp_credentials_state === 'configured' ||
        !location.pathname.includes('/orders')
    ) {
        return null;
    }
    return (
        <Dialog open>
            <DialogTitle>⚡️ Recibí el dinero instantáneamente en MercadoPago</DialogTitle>
            <DialogContent>
                <DialogContentText display="flex" flexDirection="column">
                    👀 Hoy estás recibiendo el dinero de tus clientes luego de 21 días.
                    <br />
                    <br />
                    A partir de ahora podés recibir el dinero de las ventas de Delfar
                    instantáneamente en la cuenta de MercadoPago la farmacia ⚡️.
                    <br />
                    <br />
                    Conectá MercadoPago lo antes posible para aprovechar la tasa de inversión.
                    <br />
                    <br />
                    ¿Tenés preguntas? Escribinos al:{' '}
                    <a target="_blank" href="https://wa.me/5491160008734" rel="noreferrer">
                        +54 9 11 6000-8734
                    </a>
                </DialogContentText>
                <Box sx={{ mt: 3 }} display="flex" justifyContent="center">
                    <img src="/mercadopago_logo.png?v=1" alt="Mercado Pago logo" width={150} />
                </Box>
            </DialogContent>
            <DialogActions>
                <LoadingButton variant="contained" onClick={goToConfig}>
                    Conectar 🤝
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
