import { getAuth, signOut } from 'firebase/auth';
import { endSession } from './sessions';

export const handleLogout = async () => {
    const auth = getAuth();
    if (!auth) throw new Error('getAuth returned undefined');

    await endSession().catch((err) => {
        console.error('Error ending session:', err);
    });
    await signOut(auth);
};
