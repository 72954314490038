import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSoundNotifications } from './useSoundNotifications';
import useDesktopNotifications from './useDesktopNotifications';
import { DisclosedOrder, ID, PublicOrder, requiresAttention, shouldBeShown } from '../Models';
import { healthProviderTitle } from '../utils/utils';

const initialEvent = new Date(0);
const interestingStates = (orders: PublicOrder[]) => orders;
const useNotifications = (userId: ID) => {
    const navigate = useNavigate();
    const soundNotifications = useSoundNotifications();
    const desktopNotifications = useDesktopNotifications();
    const prevOrderEvent = useRef(initialEvent); // recordamos el último evento para no repetir notificaciones

    const onOrdersSnapshot = (orders: PublicOrder[] | DisclosedOrder[], currentEvent: Date) => {
        // Si se llama a esta función dos veces, queremos evitar que se notifique de nuevo
        if (prevOrderEvent.current === currentEvent) return;
        // Anotamos la fecha de la última ejecución
        prevOrderEvent.current = currentEvent;
        // Si los pedidos son de otros estados, no nos interesa notificar
        const interestingOrders = interestingStates(orders)
            .filter((o) => requiresAttention(o) || o.state === 'fulfilled')
            .filter((o) => shouldBeShown(o, userId));

        if (!interestingOrders.length) return;

        if (prevOrderEvent.current === initialEvent) {
            // la primera vez que se ejecuta esta función
            // todo confirmar que esto anda, parece que no
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const notification = new Notification('⏱Hay pedidos esperándote', {
                icon: '/favicon-rounded-borders.png?v=3',
                requireInteraction: true,
            });
            return;
        }
        // Las siguientes veces
        const order = interestingOrders[0];

        let title = '';
        const body = healthProviderTitle(order);
        const onClick = () => navigate(`/orders/${order.id}`, { replace: true });
        let playSound = false;

        if (orders.length === 1) {
            const orderDisclosed = { ...order } as DisclosedOrder;
            const { hp_authentication: hpAuth } = orderDisclosed;

            switch (order.state) {
                case 'emitted':
                    // Solo hacemos ruido cuando el pedido NO existía previamente
                    title = '🔵 Nuevo pedido';
                    playSound = true;
                    break;
                case 'assigned':
                    playSound = true;
                    if (hpAuth?.state === 'sent') {
                        title = '🔒 Token recibido';
                    } else if (!hpAuth) {
                        title = '⚡️ Pedido asignado';
                    }
                    break;
                case 'approved':
                    title = '✅ Pedido pagado';
                    playSound = true;
                    break;
                case 'fulfilled':
                    title = '🎊 Pedido entregado 🎉';
                    break;
                case 'cancellation_requested':
                    title = '✴️ Pedido cancelado';
                    playSound = true;
                    break;
                default:
                    break;
            }
        }

        if (title) {
            desktopNotifications.showNotification(title, body, onClick);
            if (playSound) {
                soundNotifications.playNotification('order');
            }
        }
    };
    return {
        desktopNotifications,
        soundNotifications,
        onOrdersSnapshot,
    };
};
export default useNotifications;
