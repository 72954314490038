import { InternalAxiosRequestConfig } from 'axios';
import { getDefaultHeaders, APPCHECK_HEADER_PROP } from './defaultHeaders';

export const defaultRequestInterceptor = async (
    config: InternalAxiosRequestConfig<any>,
): Promise<InternalAxiosRequestConfig<any>> => {
    const defaultHeaders = await getDefaultHeaders();
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = defaultHeaders.Authorization;
    // eslint-disable-next-line no-param-reassign
    config.headers[APPCHECK_HEADER_PROP] = defaultHeaders[APPCHECK_HEADER_PROP];
    return config;
};
