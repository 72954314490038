import React, { Alert, Snackbar, AlertColor } from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

interface AlertSnackbarProps {
    id: string;
    open: boolean;
    message: string;
    severity: AlertColor;
    duration?: number;
    onClose: () => void;
}

export function AlertSnackbar(props: AlertSnackbarProps) {
    const DEFAULT_DURATION = 6000;
    const { id, open, message, severity, duration, onClose } = props;

    return (
        <Snackbar
            open={open}
            autoHideDuration={duration || DEFAULT_DURATION}
            onClose={() => onClose()}
            TransitionComponent={SlideTransition}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            key={Slide.name}>
            <Alert id={id} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    );
}
