import React from 'react';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { OrderSection } from '../OrderSection';
import { DisclosedOrder, isSchedulePending, PublicOrder } from '../../../../../Models';

const toolTipInfo = `Este pedido está programado. 

Programaste este pedido para ser enviado cuando recibas stock de los medicamentos.

El delivery será convocado automáticamente en esta fecha y horario.
`;
export function ScheduleCard({ order }: { order: PublicOrder | DisclosedOrder }) {
    let text = '';
    const scheduleDate = dayjs(order.user_offer?.scheduled_stock_datetime);
    if (scheduleDate.isToday()) {
        text = scheduleDate.format('[Hoy] hh:mm A');
    } else {
        text = scheduleDate.format('dddd DD [de] MMMM hh:mm A');
    }
    if (!order.user_offer?.scheduled_stock_datetime) {
        return null;
    }
    return (
        <OrderSection id="schedule-data">
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Tooltip
                    title={
                        <Typography style={{ whiteSpace: 'pre-line' }}>{toolTipInfo}</Typography>
                    }
                    enterDelay={1000}>
                    <CalendarMonthIcon
                        color={isSchedulePending(order) ? 'disabled' : 'secondary'}
                    />
                </Tooltip>
                <Typography>{text}</Typography>
            </Stack>
        </OrderSection>
    );
}
