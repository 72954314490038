import {
    FirestoreDataConverter,
    DocumentData,
    QueryDocumentSnapshot,
    SnapshotOptions,
} from 'firebase/firestore';
import { HealthProvider } from '../../Models';

export const healthProviderConverter: FirestoreDataConverter<HealthProvider> = {
    toFirestore(): DocumentData {
        return {};
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): HealthProvider {
        const data = snapshot.data(options)!;
        return {
            id: snapshot.id,
            long_name: data.long_name,
            name: data.name,
            type: data.type,
        };
    },
};
