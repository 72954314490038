import { useContext, useEffect, useState } from 'react';
import { useNetworkState } from 'react-use';
import { getAuth } from 'firebase/auth';
import { app } from '../services/firebase';
import { isLatestAppVersion } from '../services/webApp/version';
import { useFCMessaging } from './useFCMessaging';
import { SchedulerContext } from './useScheduler';

export const useAppState = () => {
    const [appInitialized, setAppInitialized] = useState(false);
    const [isAuthLoaded, setIsAuthLoaded] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const lastNotification = useFCMessaging();
    const [isLatestVersion, setIsLatestVersion] = useState(true);
    const {
        sessionPolling: { isError, isPaused, data: sessionId },
        appVersionPolling: { data: isLatestVersionPolling },
    } = useContext(SchedulerContext);
    const { online: browserOnline, previous: browserWasOnline } = useNetworkState();
    const isConnected = !!browserOnline && !isError && !isPaused;
    const checkIsLatestVersion = () => isLatestAppVersion().then(setIsLatestVersion);

    useEffect(() => {
        checkIsLatestVersion();
    }, []);
    useEffect(() => {
        if (browserOnline && !browserWasOnline) {
            checkIsLatestVersion();
        }
    }, [browserOnline, browserWasOnline]);
    useEffect(() => {
        if (lastNotification && lastNotification.notificationType === 'new_update_available') {
            checkIsLatestVersion();
        }
    }, [lastNotification]);
    useEffect(() => {
        if (!isLatestVersionPolling) {
            checkIsLatestVersion();
        }
    }, [isLatestVersionPolling]);

    useEffect(() => {
        if (app.name) {
            const unregisterAuthObserver = getAuth(app).onAuthStateChanged((user) => {
                setIsSignedIn(!!user);
                setIsAuthLoaded(true);
            });
            return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
        }
        return undefined;
    }, []);

    useEffect(() => {
        if (!!app.name && isAuthLoaded) {
            setAppInitialized(true);
        }
    }, [isAuthLoaded]);

    return { appInitialized, isSignedIn, isLatestVersion, isConnected, sessionId };
};
