import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import Image from 'mui-image';
import pharmaciesApi from '../../services/api/pharmacies.endpoints';
import { UserContext } from '../../services/User';
import getRedirectUri from '../../services/MercadoPago';
import { AlertSnackbar } from '../../Components/AlertSnackbar';
import { useAlertSnackbar } from '../../hooks/useAlertSnackbar';

const MP_CODE_QUERY_PARAM_NAME = 'code';
export function MercadoPagoConfig() {
    const { user } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const { alertState, alertMsg, alertSeverity, newAlert, closeAlert } = useAlertSnackbar();

    useEffect(() => {
        // esta función solo se ejecuta desde la ventana que tiene el searchParam
        const mpAuthCode = searchParams.get(MP_CODE_QUERY_PARAM_NAME);
        if (!mpAuthCode) {
            return;
        }
        pharmaciesApi.authorizeMp
            .authorize(mpAuthCode)
            .then(() => {
                newAlert('✅ Listo! Conectaste tu cuenta de Mercado Pago 🤝', 'success');
            })
            .catch((err) => {
                newAlert('No se pudo conectar tu cuenta de Mercado Pago', 'error');
                console.error(err);
            });
        searchParams.delete(MP_CODE_QUERY_PARAM_NAME);
        setSearchParams(searchParams);
    }, [navigate, newAlert, searchParams, setSearchParams]);

    const onSetupStart = async () => {
        setLoading(true);
        await pharmaciesApi.authorizeMp.warmup();
        const redirectUri = getRedirectUri();
        window.open(redirectUri, '_blank', 'noopener,noreferrer');
    };

    let msg: string;
    if (user.mp_credentials_state === 'configured') {
        msg =
            '✅ Tu cuenta de Delfar está conectada a la cuenta de Mercado Pago de tu farmacia. Recibirás instantáneamente el dinero de las ventas.';
    } else {
        msg =
            'Conectá Delfar con Mercado Pago para recibir instantáneamente el dinero de tus ventas en la cuenta de Mercado Pago de tu farmacia.';
    }
    return (
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            {user.mp_credentials_state === 'configured' ? (
                <Grid xs>
                    <Typography>{msg}</Typography>
                </Grid>
            ) : (
                <>
                    <Grid>
                        <Typography>{msg}</Typography>
                    </Grid>
                    <Grid>
                        <Typography>
                            1. Apretá <b>Conectar 🤝</b>
                        </Typography>
                        <Typography>
                            2. Iniciá sesión en la cuenta de Mercado Pago de la farmacia.
                        </Typography>
                        <Typography>
                            3. Seguí los pasos de la ventana apretando en <b>Continuar</b>.
                        </Typography>
                        <Typography>
                            4. Luego apretá en <b>Autorizar esta aplicación</b>.
                        </Typography>
                        <Typography>5. Listo!</Typography>
                        <LoadingButton
                            loading={loading}
                            onClick={onSetupStart}
                            variant="contained"
                            sx={{ mt: 4, width: '15vw' }}>
                            Conectar 🤝
                        </LoadingButton>
                    </Grid>
                </>
            )}
            <Grid xs={4}>
                <Image
                    alt="Logo Mercado Pago"
                    width="15vw"
                    src="/mercadopago_logo.png?v=1"
                    duration={0}
                />
            </Grid>
            <AlertSnackbar
                id="mp-alert-snackbar"
                severity={alertSeverity}
                message={alertMsg}
                open={alertState}
                onClose={() => closeAlert()}
            />
        </Grid>
    );
}
