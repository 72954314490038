import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import Slider from '@mui/material/Slider';
import { useContext, useState } from 'react';
import { SoundNotificationsContext } from '../../hooks/useSoundNotifications';

export function Notifications() {
    const { playNotification, currentVolume, setVolume } = useContext(SoundNotificationsContext);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [_newVolume, _setVolume] = useState(currentVolume);

    const handleVolumeChange = (event: Event, newValue: number | number[]) => {
        _setVolume(newValue as number);
    };
    const handleVolumeSet = () => {
        setVolume(_newVolume as number);
        playNotification('order', true);
    };
    return (
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid xs={6}>
                <Typography gutterBottom>Volumen</Typography>
                <Stack direction="row" spacing={2} alignItems="center">
                    {_newVolume === 0 ? <VolumeOffIcon /> : <VolumeUpIcon />}
                    <Slider
                        min={0}
                        max={100}
                        value={_newVolume}
                        onChange={handleVolumeChange}
                        onMouseUp={handleVolumeSet}
                    />
                </Stack>
            </Grid>
        </Grid>
    );
}
