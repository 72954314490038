// eslint-disable-next-line import/no-named-default
import { default as _isURL } from 'validator/lib/isURL';
import { PublicOrder, UserOffer } from '../Models';
import { translateHealthProvider } from '../services/HealthProviders';

export const debug: boolean = process.env.DEBUG === 'true' || false;

// **** DEPRECATED
export const getTotalCosts = (userOffer: UserOffer | undefined) => {
    if (!userOffer) return 0;
    let drugsTotal = 0;
    drugsTotal += userOffer.prescription_charged_price || 0;
    return drugsTotal;
};
// ****

export const whatsappLink = (phoneNumber: string) => `https://wa.me/${phoneNumber}`;

export const healthProviderTitle = (order: PublicOrder | undefined, hasOtc?: boolean): string => {
    if (!order) return '';
    let title = translateHealthProvider(order.health_provider);
    if (order.health_provider !== 'otc' && hasOtc) {
        title = `${title} + Venta Libre`;
    }
    return title;
};

export const isURL = (value: string) =>
    _isURL(value, { protocols: ['https'], require_protocol: true });

export const capitalizeEachWord = (str: string | undefined): string =>
    (str || '').toLowerCase().replace(/(^|\s)\S/g, (char) => char.toUpperCase());
