import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';

const askNotificationPermission = async () => {
    try {
        return await Notification.requestPermission();
    } catch (e) {
        console.error(e);
    }
    return null;
};
export default function InitDesktopNotifications() {
    const [open, setOpen] = useState(Notification.permission === 'default');
    const [requesting, setRequesting] = useState(false);
    const displayImage = navigator.language.includes('en')
        ? '/notifications-allow-en.png?v=1'
        : '/notifications-allow-es.png?v=1';
    if (!Reflect.has(window, 'Notification') || Notification.permission !== 'default') {
        return null;
    }
    if (requesting) {
        askNotificationPermission()
            .then((permission) => {
                if (permission === 'denied') {
                    console.warn('Notifications permission was denied');
                    setRequesting(false);
                } else {
                    setTimeout(() => {
                        setOpen(false);
                    }, 4000);
                    setRequesting(false);
                }
            })
            .catch((e) => console.error(e));
    }
    return (
        <Dialog open={open}>
            <DialogTitle>Nunca te pierdas un pedido 💪🏻</DialogTitle>
            <DialogContent>
                <DialogContentText display="flex" flexDirection="column">
                    Permití las notificaciones para recibir avisos sobre nuevos pedidos
                    <br />
                    <Box sx={{ mt: 1 }} display="flex" justifyContent="center">
                        <img
                            src={displayImage}
                            alt="Delfar title"
                            width={400}
                            style={{ borderRadius: '2%' }}
                        />
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={() => setRequesting(true)} loading={requesting}>
                    Permitir
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
