import { AxiosRequestHeaders } from 'axios';
import { getTokensInParallel } from '../../auth/getTokensInParallel';

export const APPCHECK_HEADER_PROP = 'X-Firebase-AppCheck';

export const getDefaultHeaders = async () => {
    const { idToken, appCheckToken } = await getTokensInParallel();
    const headers: Partial<AxiosRequestHeaders> = {
        Authorization: `Bearer ${idToken}`,
        'X-Firebase-AppCheck': appCheckToken || '',
    };
    return headers;
};
