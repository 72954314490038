import { round } from 'reliable-round';
import { OfferProductsReqDto } from '../../../services/api/dto/orders.dto';
import { OfferProductFormValues } from '../types/editableOfferCard.formValues';

export const sanitizeProductsForm = (products: OfferProductFormValues[]): OfferProductsReqDto[] =>
    products
        .filter((prod) => !!prod.name)
        .map((p) => ({
            ...p,
            unit_price: round((p.unit_price || 0) * 100), // centavos, sin coma
            discount_percent: p.discount_percent || 0,
        }));
